import { inject, Injectable } from '@angular/core';
import { FetchService } from './fetch.service';
import { StateService } from './state.service';


@Injectable({
  providedIn: 'root'
})
export class PullService {
  private $fetch = inject(FetchService);
  private $state = inject(StateService);

  // Pull the cart for the current user
  public async cart(): Promise<void> {
    const cart = await this.$fetch.cart();
    this.$state.bCart.next(cart);
  }

  // Pull orders for user
  public async orders(): Promise<void> {
    const orders = await this.$fetch.orders();
    this.$state.bOrders.next(orders);
  }

  // Pull order by id for user
  public async order(id: string): Promise<void> {
    const order = await this.$fetch.orderById(id);
    if (!order) return;
    const stateOrders = this.$state.bOrders.getValue();
    const index = stateOrders.findIndex(i => i.id === order.id);
    if (index < 0) return;
    const updatedOrders = [
      ...stateOrders.slice(0, index),
      order,
      ...stateOrders.slice(index + 1)
    ];
    this.$state.bOrders.next(updatedOrders);
  }

  public async categories(): Promise<void> {
    const categories = await this.$fetch.categories();
    this.$state.bCategories.next(categories);
  }

  public async subCategories(): Promise<void> {
    const subCategories = await this.$fetch.subCategories();
    this.$state.bSubCategories.next(subCategories);
  }

  public async medicalProfiles(): Promise<void> {
    const medicalProfiles = await this.$fetch.medicalProfiles();
    this.$state.bMedicalProfiles.next(medicalProfiles);
  }

  public async user(): Promise<void> {
    const user = await this.$fetch.user();
    this.$state.bUser.next(user);
  }

  // Pull session for user
  public async session(): Promise<void> {
    const session = await this.$fetch.session();
    this.$state.bSession.next(session);
  }

  // Pull cards for user
  public async cards(): Promise<void> {
    const cards = await this.$fetch.cards();
    this.$state.bCards.next(cards);
  }

  // Pull notifications for user
  public async notifications(): Promise<void> {
    const notifications = await this.$fetch.notifications();
    this.$state.bNotification.next(notifications || []);
  }

  public async customerPromotions(): Promise<void> {
    const customerPromotions = await this.$fetch.customerPromotions();
    this.$state.bPromotionCustomer.next(customerPromotions);
  }

}
