import { Component, computed, input } from '@angular/core';
import { TFulfillmentMethodName } from '@chemist2u/types-client/C2U/Interfaces';

@Component({
  selector: 'app-delivery-cart',
  templateUrl: './delivery-cart.component.html',
  styleUrls: ['./delivery-cart.component.scss'],
  standalone: true,
})
export class DeliveryCartComponent {
  public count = input.required<number>();
  public multiOrder = input<boolean>(false);
  public type = input<TFulfillmentMethodName | undefined>(undefined);

  public label = computed(() => {
    const type = this.type() || 'default'; 
    const labels = {
      clickAndCollect: 'Collection',
      OnDemand: 'Delivery',
      Standard: 'Delivery',
      Postal: 'Delivery',
      PostalTemperatureControlled: 'Delivery',
      default: 'Delivery'  // Default message as a part of the map
    };

    return labels[type];
  });
  public description = computed(() => {
    const type = this.type() || 'default'; 
    const labels = {
      clickAndCollect: 'These items are supplied via a local pharmacy',
      OnDemand: 'These items are supplied via a local pharmacy',
      Standard: 'These items are supplied via a local pharmacy',
      Postal: 'These items are supplied via mail order',
      PostalTemperatureControlled: 'These items are supplied via mail order',
      default: 'These items are supplied via a local pharmacy'  // Default message as a part of the map
    };

    return labels[type];
  });

}