import { environment } from './environments/environment';
import * as Sentry from "@sentry/capacitor";
import * as SentryAngular from "@sentry/angular";

export const sentryInit = () => {
  Sentry.init({
    dsn: environment.sentryDSN,
    integrations: [
      SentryAngular.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", environment.serverUrl],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: environment.production ? "production" : "development",
  }, SentryAngular.init);
};