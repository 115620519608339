import { Component, input, output } from '@angular/core';
import { SimpleButtonComponent } from 'src/app/components/primitives/simple-button/simple-button.component';

@Component({
  selector: 'app-checkout-header',
  templateUrl: './checkout-header.component.html',
  styleUrls: ['./checkout-header.component.scss'],
  standalone: true,
  imports: [SimpleButtonComponent]
})
export class CheckoutHeaderComponent {
  public title = input.required<string>();
  public description = input<string>();
  public action = input<boolean>(false);
  public actionLabel = input<string>("Action");

  public actionEvent = output<void>();

  actionClicked() {
    this.actionEvent.emit();
  }
}