import { inject, Injectable } from '@angular/core';
import { Geolocation, Position } from '@capacitor/geolocation';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import { StateService } from './state.service';
import { CloudFunctionsService } from './cloud.functions.service';
import { ParseGeoPoint } from '@chemist2u/types-client/C2U/default';
import { NativePermissionService } from './native-permission.service';

type PharmacyLocation = {
  pharmacyId: string;
  location: ParseGeoPoint;
};

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {
  private $state = inject(StateService);
  private $cloud = inject(CloudFunctionsService);
  private $nativePermission = inject(NativePermissionService);

  private positionWatchId?: string;
  private pharmacyLocationsSubscription?: Subscription;
  private pharmacyDistanceSubscription?: Subscription;
  
  private currentGeoPoint: BehaviorSubject<ParseGeoPoint | undefined> = new BehaviorSubject<ParseGeoPoint | undefined>(undefined);
  private pharmacyLocations: BehaviorSubject<PharmacyLocation[]> = new BehaviorSubject<PharmacyLocation[]>([]);
  
  // Range in meters to trigger inPharmacyRange
  private readonly pharmacyRange = 50;
  // Broadcasts the pharmacy objectId of a pharmacy in range on last distance calculations
  public inPharmacyRange: Subject<string> = new Subject<string>();

  constructor() {
    this.recordCurrentLocationOnChange();
  }

  recordCurrentLocationOnChange() {
    console.warn("[GeolocationService]", "recordCurrentLocation Start");
    this.$state.bGeoLocation.subscribe(async position => {
      if (!position || !position.coords) return;
      this.registerPosition(position);
      const result = await this.$cloud.googleGeocode(position.coords.latitude.toString(), position.coords.longitude.toString())
      if (!result) return;
      this.$state.bCurrentLocation.next(result);
      console.warn("[GeolocationService]", "recordCurrentLocation Result", result);
    });
  }

  async init() {
    await this.listenPharmacyLocations();
  }

  registerPosition(position: Position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    const currentGeoPoint = new ParseGeoPoint(latitude, longitude);
    this.currentGeoPoint.next(currentGeoPoint);
  }

  async checkPermission() {
    let hasPermissions = this.$nativePermission.getPermissionsFor("LOCATION");
    if (!hasPermissions) {
      hasPermissions = await this.$nativePermission.requestPermissionsFor("LOCATION");
      if (hasPermissions != "granted") return false;
    }
    return true;
  }

  async getCurrentPosition() {
    const hasPermissions = await this.checkPermission();
    if (!hasPermissions) return;
    console.warn("[GeolocationService]", "getCurrentPosition Start");
    const position = await Geolocation.getCurrentPosition();
    console.warn("[GeolocationService]", "getCurrentPosition Result", position);
    this.$state.bGeoLocation.next(position);
  }

  async watchPosition() {
    const hasPermissions = await this.checkPermission();
    if (!hasPermissions) return;
    if (this.positionWatchId) return;
    console.warn("[GeolocationService]", "watchPosition Start");
    // TODO: Triple check watchPosition options
    this.positionWatchId = await Geolocation.watchPosition({
      enableHighAccuracy: false,
      timeout: 1000,
      maximumAge: 2
    }, (position: Position | null, err?: any) => {
      if (!position) return;
      console.warn("[GeolocationService]", "watchPosition Result", position);
      this.$state.bGeoLocation.next(position);
    });
  }

  async clearWatchPosition() {
    if (!this.positionWatchId) return;
    await Geolocation.clearWatch({ id: this.positionWatchId! }).then(() => {
      console.warn("[GeolocationService]", "watchPosition Cleared");
    });
  }

  async listenPharmacyLocations() {
    if (this.pharmacyLocationsSubscription) return;
    this.pharmacyLocationsSubscription = this.$state.bOrders.subscribe(orders => {
      const ordersForPickup = orders.filter(order => {
        const isClickAndCollect = order.fulfillmentDetails?.selectedMethod.method == "clickAndCollect";
        const isReady = order.status == "Ready";
        return isClickAndCollect && isReady;
      });
      const pharmacyLocations: PharmacyLocation[] = [];
      if (ordersForPickup.length > 0) {
        ordersForPickup.forEach(order => {
          if (!order.pharmacy?.location) return;
          const pharmacyLocation = order.pharmacy.location as ParseGeoPoint;
          pharmacyLocations.push({ pharmacyId: order.pharmacy.objectId, location: pharmacyLocation });
        });
      }
      if (pharmacyLocations.length > 0) {
        this.watchDistanceToPharmacies();
      } else {
        this.unwatchDistanceToPharmacies();
      }
      this.pharmacyLocations.next(pharmacyLocations);
    });
  }

  async unlistenPharmacyLocations() {
    if (this.pharmacyLocationsSubscription) this.pharmacyLocationsSubscription.unsubscribe();
  }

  async watchDistanceToPharmacies() {
    if (this.pharmacyDistanceSubscription) return;
    this.watchPosition();
    this.pharmacyDistanceSubscription = combineLatest([
      this.currentGeoPoint, 
      this.pharmacyLocations
    ]).subscribe(([currentGeoPoint, pharmacyLocations]) => {
      if (!currentGeoPoint) return;
      this.handleLocationChange(currentGeoPoint, pharmacyLocations);
    });
  }

  async unwatchDistanceToPharmacies() {
    this.clearWatchPosition();
    if (this.pharmacyDistanceSubscription) this.pharmacyDistanceSubscription.unsubscribe();
  }

  async handleLocationChange(currentGeoPoint: ParseGeoPoint, pharmacyLocations: PharmacyLocation[]) {
    pharmacyLocations.forEach(pharmacyLocation => {
      const pharmacyDistance = pharmacyLocation.location.kilometersTo(currentGeoPoint);
      const distanceInMeters = pharmacyDistance * 1000;
      console.warn(`Distance from pharmacy ${pharmacyLocation.pharmacyId} is ${distanceInMeters} meters`);
      if (distanceInMeters < this.pharmacyRange) {
        this.inPharmacyRange.next(pharmacyLocation.pharmacyId);
      }
    });
  }
}
