<div class="row" [class.nopadding]="nopadding()" [class.last]="last()">
  <div class="brand-icon">
    <app-image [name]="cardBrandIcon()" [isIcon]="true" [local]="true" />
  </div>
  <div class="number">
    <div class="dots">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
    <div class="lastfour">{{ card()?.last4 }}</div>
  </div>
  <div class="action-icon" [ngClass]="{ tinted: mode() == 'delete' }">
    @if (mode() == "select") {
      <ion-icon name="chevron-forward"></ion-icon>
    } @else if (mode() == "delete") {
      <ion-icon (click)="deleteCard($event)" name="trash-bin-outline"></ion-icon>
    }
  </div>
</div>