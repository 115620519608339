import { inject, Injectable } from '@angular/core';
import { Credentials, NativeBiometric } from 'capacitor-native-biometric';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { PreferencesService } from './preferences.service';

@Injectable({
  providedIn: 'root'
})
export class BiometricsService {
  private $amplitude = inject(AmplitudeService);
  private $preferences = inject(PreferencesService);

  private readonly BIOMETRIC_IDENTIFIER = 'parse_server';

  constructor() {
    this.init();
  }

  async init() {
    const isFirstStartup = await this.$preferences.checkFirstStartup();
    if (!isFirstStartup) return;
    const hasBioSetup = await this.hasBioSetup();
    if (!hasBioSetup) return;
    await this.removeBio();
  }

  async hasBio(): Promise<boolean> {
    try {
      const hasBio = await NativeBiometric.isAvailable();
      if (hasBio.errorCode || !hasBio.isAvailable) return false;
      return true;
    } catch (error) {
      return false;
    }
  }

  async hasBioSetup(): Promise<boolean> {
    const hasBio = await this.hasBio();
    if (!hasBio) return false;
    try {
      const credentials = await NativeBiometric.getCredentials({ server: this.BIOMETRIC_IDENTIFIER });
      return !!credentials;
    } catch (error) {
      return false;
    }
  }

  async setupBio(username: string, password: string): Promise<void> {
    try {
      await NativeBiometric.setCredentials({
        username,
        password,
        server: this.BIOMETRIC_IDENTIFIER,
      });
      this.$amplitude.track('BIOMETRICS_SETUP_INITIATED');
      const valid = await this.verifyBio();
      if (!valid) throw new Error("Error verifying biometrics");
      this.$amplitude.track('BIOMETRICS_SETUP_SUCCESS');
    } catch (error) {
      await this.removeBio();
      this.$amplitude.track('BIOMETRICS_SETUP_FAIL');
      throw error;
    }
  }

  async verifyBio(): Promise<Credentials | undefined> {
    try {
      await NativeBiometric.verifyIdentity({
        reason: "For easy log in",
        title: "Log in",
        subtitle: 'Authenticate',
        description: 'Please authenticate to proceed',
        maxAttempts: 2,
        useFallback: true,
      });
      this.$amplitude.track('BIOMETRICS_VERIFY_SUCCESS');
      return await NativeBiometric.getCredentials({ server: this.BIOMETRIC_IDENTIFIER });
    } catch (error) {
      console.error("[BiometricsService]", `Error verifiying biometrics:`, error);
      this.$amplitude.track('BIOMETRICS_VERIFY_FAIL');
      return undefined;
    }
  }

  async removeBio() {
    try {
      await NativeBiometric.deleteCredentials({ server: this.BIOMETRIC_IDENTIFIER });
      this.$amplitude.track('BIOMETRICS_REMOVE_SUCCESS');
    } catch (error) {
      this.$amplitude.track('BIOMETRICS_REMOVE_FAIL');
    }
  }
}