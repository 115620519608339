<label>
  {{ label() }}
  @if (required()) { <span class="required">*</span> }
</label>
<div>
  <textarea
    [ngModel]="value()"
    (ngModelChange)="onValueChange($event)"
  ></textarea>
</div>

@if (errorMessage() && touched()) {
  <span class="error-message">{{ errorMessage() }}</span>
}