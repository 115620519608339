<div class="row" [class.simple]="simple()" [class.last]="last()">
  <div class="thumbnail" (click)="editClick()" >
    <app-image [src]="productThumbnailSrc" />
  </div>
  <div class="details" (click)="editClick()" >
    <div class="label">{{productName()}}</div>
    @if (!simple()) {
      <div class="price">
        <div class="label">{{productQuantity()}} &times;</div><app-price [value]="productPrice()" />
      </div>
    }    
  </div>
  @if (!simple() && canRemove()) {
    <div class="actions">
      <app-simple-button size="medium" (click)="removeItem()" icon="trash-bin-outline" />
    </div>
  }
</div>