import { APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { provideHttpClient } from '@angular/common/http';
import { RouteReuseStrategy, provideRouter, withPreloading, PreloadAllModules, Router } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { routes } from './app/app.routes';
import * as SentryAngular from "@sentry/angular";

export const AppConfig = {
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        provideHttpClient(),
        provideIonicAngular({useSetInputAPI: true}),
        provideRouter(routes, withPreloading(PreloadAllModules)),
        {
            provide: ErrorHandler,
            useValue: SentryAngular.createErrorHandler(),
        },
        {
            provide: SentryAngular.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [SentryAngular.TraceService],
            multi: true,
        },
    ],
};