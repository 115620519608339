import { inject, Injectable } from '@angular/core';
import { Cart } from '@chemist2u/types-client/C2U/ParseObjects';
import { TLoadItemSingleResult } from '@chemist2u/types-client/C2U/Cloud/item';
import { TCartItem, TSerializedParseFile } from '@chemist2u/types-client/C2U/Interfaces';
import { PushService } from './push.service';
import { StateService } from './state.service';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private $state = inject(StateService);
  private $push = inject(PushService);
  private $error = inject(ErrorService);

  private bCart = this.$state.bCart;

  async addItem(item: TLoadItemSingleResult, qty: number, swapEnabled: boolean = false) {
    try {
      const transformer = (item: TLoadItemSingleResult): TCartItem => {
        return {
          name: item.item.name!,
          price: item.override?.price || item.item.price!,
          qty: qty,
          salePrice: item.item.salePrice || 0,
          featuredImage: item.item.featuredImage as TSerializedParseFile,
          slug: item.item.slug!,
          featuredImageThumb: item.item.featuredImageThumb as TSerializedParseFile,
          basePrice: item.item.price!,
          schedule: item.item.schedule!,
          objectId: item.item.objectId,
          amount: item.override?.price || item.item.price!,
          swapEnabled: swapEnabled,
        }
      }

      let cart = this.bCart.getValue();
      if (!cart) {
        cart = await this.newCart()
      }
      const items = cart.items;

      //If item exists in cart stop and warn
      if (items!.find(i => i.objectId === item.item.objectId)) return this.$error.showToast({ message: "Item already in cart.", header: "Error", "position": "top", duration: 2000 });

      //Otherwise add to cart and save
      items!.push(transformer(item))
      cart.set('items', items);
      await this.$push.cart(cart);
    } catch (e: any) {
      this.$error.showToast({ message: "Error while trying to add item to cart", header: "Error", "position": "top", duration: 2000 });
      this.$error.handleParseError(e);
      throw e;
    }
  }

  async removePromotion() {
    const cart = this.bCart.getValue();
    if (!cart) return;
    cart.unset('promotion');
    await this.$push.cart(cart);
  }

  async removeOTCItem(id: string) {
    const cart = this.bCart.getValue();
    if (!cart) return;
    const items = cart?.items;
    if (!items) return;
    const updatedItems = items.filter(i => i.objectId !== id);
    await this.$push.cart(cart, {
      ...cart.attributes,
      items: updatedItems
    });
  }

  async modifyOTCItemQty(id: string, qty: number) {
    const cart = this.bCart.getValue();
    if (!cart) return;
    const items = cart?.items;
    if (!items) return;
    const updatedItems = items.map(i => {
      if (i.objectId === id) {
        return {
          ...i,
          qty: qty
        }
      }
      return i;
    });
    await this.$push.cart(cart, {
      ...cart.attributes,
      items: updatedItems
    });
  }

  async newCart(): Promise<Cart> {
    try {
      const cart = new Cart({
        discount: 0,
        items: [],
        prescriptions: [],
        subtotal: 0,
        total: 0,
        customer: this.$state.bUser.getValue(),
      });
      return await this.$push.cart(cart);
    } catch (e: any) {
      this.$error.handleParseError(e);
      throw e;
    }
  }

}
