import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

export type TScriptName = "hubspot" | "square";

export interface IScriptDef {
  name: TScriptName;
  localSrc: string;
  prodSrc: string;
}

export const ScriptStore: IScriptDef[] = [
  {
    name: "hubspot",
    localSrc: "https://js.hs-scripts.com/7375018.js",
    prodSrc: "https://js.hs-scripts.com/7375018.js"
  },
  {
    name: "square",
    localSrc: "https://sandbox.web.squarecdn.com/v1/square.js",
    prodSrc: "https://web.squarecdn.com/v1/square.js",
  },
];

export interface IScript {
  name: TScriptName;
  loaded: boolean;
  src: string;
}

declare var document: any;

@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {
  private scripts: Map<TScriptName, IScript> = new Map<TScriptName, IScript>();

  constructor() {
    ScriptStore.forEach((script: IScriptDef) => {
      this.scripts.set(script.name, {
        name: script.name,
        loaded: false,
        src: environment.production ? script.prodSrc : script.localSrc
      });
    });
  }

  load(...scripts: TScriptName[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  private loadScript(name: TScriptName): Promise<string> {
    return new Promise(async (resolve, reject) => {
      const script = this.scripts.get(name);
      if (!script) {
        return reject(`[ScriptLoader] "${name}" definition doesn't exist`);
      }
      if (script.loaded) {
        return resolve(`[ScriptLoader] "${name}" already loaded!`);
      }
      const result = await this.loadJS(script.src);
      script.loaded = result;
      if (!result) {
        return reject(`[ScriptLoader] "${name}" load error!`);
      }
      return resolve(`[ScriptLoader] "${name}" loaded successfully!`);
    });
  }
  
  private loadJS(src: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const scriptEl = document.createElement('script');
      scriptEl.type = 'text/javascript';
      scriptEl.src = src;

      if (scriptEl.readyState) {  //IE
        scriptEl.onreadystatechange = () => {
          if (scriptEl.readyState === "loaded" || scriptEl.readyState === "complete") {
            scriptEl.onreadystatechange = null;
            resolve(true);
          }
        };
      } else {
        scriptEl.onload = () => {
          resolve(true);
        };
      }
      scriptEl.onerror = (error: unknown) => resolve(false);
      document.getElementsByTagName('head')[0].appendChild(scriptEl);
    });
  }
}
