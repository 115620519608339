import { Component, input } from '@angular/core';
import { IonIcon } from "@ionic/angular/standalone";
import { addIcons } from 'ionicons';
import { chevronForward } from 'ionicons/icons';

@Component({
  selector: 'app-coupon-row',
  templateUrl: './coupon-row.component.html',
  styleUrls: ['./coupon-row.component.scss'],
  standalone: true,
  imports: [IonIcon]
})
export class CouponRowComponent {

  constructor() {
    addIcons({ chevronForward });
  }

  public last = input<boolean>(false);
}