import { Component, ElementRef, input, model, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonSpinner, IonIcon } from "@ionic/angular/standalone";
import { addIcons } from 'ionicons';
import { closeCircle } from 'ionicons/icons';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
  standalone: true,
  imports: [IonSpinner, IonIcon, FormsModule]
})
export class OtpComponent {
  @ViewChild('otpinput', { static: false }) iOTOInput!: ElementRef;

  
  constructor() {
    addIcons({ closeCircle });
  }
  
  otpCode = model<string>('');
  showLoading = input<boolean>(false);

  clearCode() {
    this.otpCode.set('');
  }
}