import { Component, input, model, OnInit, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  standalone: true,
  imports: [FormsModule]
})
export class TextareaComponent {

  public label = input<string | undefined>(undefined);
  public required = input<boolean>(false);
  public touched = signal<boolean>(false);
  public value = model<string | undefined>(undefined);
  public errorMessage = input<string | undefined>(undefined);

  onValueChange(value: string) {
    this.touched.set(true);
    this.value.set(value);
  }

  

}
