import { Component, input } from '@angular/core';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
  standalone: true,
})
export class LabelComponent  {

  public text = input.required<string>();

  

}
