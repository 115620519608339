import { IonIcon } from '@ionic/angular/standalone';
import { Component, computed, input, output } from '@angular/core';
import { addIcons } from 'ionicons';
import { chevronForwardOutline, closeOutline } from 'ionicons/icons';
import { PromotionCustomer } from '@chemist2u/types-client/C2U/ParseObjects';
import { TSerializedParseObject } from '@chemist2u/types-client/C2U/default';
import C2U from '@chemist2u/types-client';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-promotion-row',
  templateUrl: './promotion-row.component.html',
  styleUrls: ['./promotion-row.component.scss'],
  standalone: true,
  imports: [IonIcon, DatePipe]
})
export class PromotionRowComponent {
  
  promotion = input<TSerializedParseObject<C2U.Interfaces.IPromotionCustomer> | PromotionCustomer>();
  last = input<boolean>(false);
  past = input<boolean>(false);
  canSelect = input<boolean>(false);
  canClear = input<boolean>(false);
  selected = output<TSerializedParseObject<C2U.Interfaces.IPromotionCustomer> | PromotionCustomer>();
  clearEvent = output<void>({ alias: 'clear' });
  noPadding = input<boolean>(false);

  constructor() {
    addIcons({ chevronForwardOutline, closeOutline });
  }

  code = computed<string | undefined>(() => {
    const promotion = this.promotion();
    if (promotion == undefined) return undefined;
    const promoObj = promotion.promotionObject;
    return promoObj?.code;
  });

  clear() {
    if(!this.canClear()) return;
    this.clearEvent.emit();
  }

  expiryDate = computed<Date | undefined>(() => {
    const promotion = this.promotion();
    if (promotion == undefined) return undefined;
    const promoObj = promotion.promotionObject;
    const expiry = promoObj?.expiry;
    if (expiry == undefined) return undefined;
    return expiry instanceof Date ? expiry : new Date(expiry?.iso);
  });

  description = computed<string>(() => {
    const promotion = this.promotion();
    if (promotion == undefined) return "";
    const promoObj = promotion.promotionObject;
    
    const classOTC = promoObj?.classOTC;
    const classScript = promoObj?.classScript;
    const classFee = promoObj?.classFee;
    
    let returnString = "";

    if (promoObj?.type == "amount") {
      returnString += "$" + promoObj?.value;
    }

    if (promoObj?.type == "percentage") {
      returnString += promoObj?.value + "%";
    }

    returnString += " off ";

    if (classOTC && !classScript && !classFee) {
      returnString += "all OTC items";
    }

    if (classOTC && classScript && !classFee) {
      returnString += "all items (not including shipping)";
    }

    if (classOTC && classScript && classFee) {
      returnString += "entire order";
    }

    if (!classOTC && classScript && !classFee) {
      returnString += "all scripts";
    }

    if (!classOTC && classScript && classFee) {
      returnString += "all scripts & shipping";
    }

    if (!classOTC && !classScript && classFee) {
      returnString += "shipping";
    }

    if (!classOTC && !classScript && classFee && promoObj?.type == "percentage" && promoObj?.value == 100) {
      returnString = "Free shipping";
    }
    return returnString;
  });

  selectedRow() {
    const promotion = this.promotion();
    if (this.canSelect() && promotion != undefined) {
      this.selected.emit(promotion);
    }
  }

}