import { inject, Injectable } from '@angular/core';
import {
  CardEway,
  Cart,
  CustomerMedicalProfile,
  CustomerMedicalProfile_New,
  CustomerSessionDetails,
  Order,
  PromotionCustomer,
  User,
} from '@chemist2u/types-client/C2U/ParseObjects';
import { ICardEway, ICart, ICustomerMedicalProfile, ICustomerMedicalProfile_New, ICustomerSessionDetails, IOrder, IUser } from '@chemist2u/types-client/C2U/Interfaces';
import { ErrorService } from './error.service';


@Injectable({
  providedIn: 'root'
})
export class PushService {
  private $error = inject(ErrorService);

  public async card(card: CardEway, attrs?: ICardEway | Pick<ICardEway, keyof ICardEway> | null): Promise<CardEway> {
    return await this.$error.parseErrorWrapper(async () => {
      return attrs ? await card.save(attrs) : await card.save();
    });
  }

  public async newCard(card: ICardEway): Promise<CardEway> {
    return await this.$error.parseErrorWrapper(async () => {
      return await new CardEway(card).save();
    });
  }

  public async destroyCard(card: CardEway): Promise<void> {
    await this.$error.parseErrorWrapper(async () => {
      await card.destroy();
    });
  }
  
  public async medicalProfile(profile: CustomerMedicalProfile, attrs?: ICustomerMedicalProfile | Pick<ICustomerMedicalProfile, keyof ICustomerMedicalProfile> | null): Promise<CustomerMedicalProfile> {
    return await this.$error.parseErrorWrapper(async () => {
      return attrs ? await profile.save(attrs) : await profile.save();
    });
  }
  
  public async newMedicalProfile(profile: ICustomerMedicalProfile): Promise<CustomerMedicalProfile> {
    return await this.$error.parseErrorWrapper(async () => {
      return await new CustomerMedicalProfile(profile).save();
    });
  }

  public async medicalProfileNew(profile: CustomerMedicalProfile_New, attrs?: ICustomerMedicalProfile_New | Pick<ICustomerMedicalProfile_New, keyof ICustomerMedicalProfile_New> | null): Promise<CustomerMedicalProfile_New> {
    return await this.$error.parseErrorWrapper(async () => {
      return attrs ? await profile.save(attrs) : await profile.save();
    });
  }

  public async newMedicalProfileNew(profile: ICustomerMedicalProfile_New): Promise<CustomerMedicalProfile_New> {
    return await this.$error.parseErrorWrapper(async () => {
      return await new CustomerMedicalProfile_New(profile).save();
    });
  }

  public async order(order: Order, attrs?: IOrder | Pick<IOrder, keyof IOrder> | null): Promise<Order> {
    return await this.$error.parseErrorWrapper(async () => {
      return attrs ? await order.save(attrs, { context:  { source: "appv2"}}) : await order.save(null, { context:  { source: "appv2"}});
    });
  }

  public async newOrder(order: IOrder): Promise<Order> {
    return await this.$error.parseErrorWrapper(async () => {
      return await new Order(order).save(null, { context:  { source: "appv2"}});
    });
  }

  public async cart(cart: Cart, attrs?: ICart | Pick<ICart, keyof ICart> | null): Promise<Cart> {
    return await this.$error.parseErrorWrapper(async () => {
      return attrs ? await cart.save(attrs, { context:  { source: "appv2"}}) : await cart.save(null, { context: { source: "appv2"}});
    });
  }

  public async newCart(cart: ICart): Promise<Cart> {
    return await this.$error.parseErrorWrapper(async () => {
      return await new Cart(cart).save(null, { context:  { source: "appv2"}});
    });
  }

  public async session(session: CustomerSessionDetails, attrs?: ICustomerSessionDetails | Pick<ICustomerSessionDetails, keyof ICustomerSessionDetails> | null): Promise<CustomerSessionDetails> {
    return await this.$error.parseErrorWrapper(async () => {
      return attrs ? await session.save(attrs) : await session.save();
    });
  }

  public async newSession(session: ICustomerSessionDetails): Promise<CustomerSessionDetails> {
    return await this.$error.parseErrorWrapper(async () => {
      return await new CustomerSessionDetails(session).save();
    });
  }

  public async user(user: User, attrs?: Parse.Attributes | Pick<Parse.Attributes, string> | null): Promise<User> {
    return await this.$error.parseErrorWrapper(async () => {
      return attrs ? await user.save(attrs) : await user.save();
    });
  }

  public async newUser(user: IUser): Promise<User> {
    return await this.$error.parseErrorWrapper(async () => {
      return await new User(user).save();
    });
  }

  public async destroyPromotion(promotion: PromotionCustomer): Promise<void> {
    await this.$error.parseErrorWrapper(async () => {
      await promotion.destroy();
    });
  }

}
