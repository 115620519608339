import { Component, computed, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonContent, IonHeader, ModalController } from '@ionic/angular/standalone';
import { NativeMarket } from '@capgo/native-market';
import { HeaderComponent } from 'src/app/components/navigation/header/header.component';
import { SimpleButtonComponent } from 'src/app/components/primitives/simple-button/simple-button.component';
import { TitleComponent } from 'src/app/components/primitives/title/title.component';
import { AppUpdateService } from 'src/app/services/app-update.service';
import { AppUpdates } from '@chemist2u/types-client/C2U/AppUpdates';
import { Subscription } from 'rxjs';
import { ButtonComponent } from 'src/app/components/primitives/button/button.component';
import { ProgressBarComponent } from 'src/app/components/elements/progress-bar/progress-bar.component';
import { App } from '@capacitor/app';
import { ErrorService } from 'src/app/services/error.service';
import { ImageComponent } from 'src/app/components/primitives/image/image.component';
import { AmplitudeService } from 'src/app/services/amplitude.service';

type TStatus = "pending" | "downloading" | "downloaded" | "updating" | "completed";

@Component({
  selector: 'app-app-updater',
  templateUrl: './app-updater.page.html',
  styleUrls: ['./app-updater.page.scss'],
  standalone: true,
  imports: [IonContent, IonHeader, CommonModule, HeaderComponent, SimpleButtonComponent, TitleComponent, ButtonComponent, ProgressBarComponent, ImageComponent]
})
export class AppUpdaterPage {
  private modalController = inject(ModalController);
  private $appUpdate = inject(AppUpdateService);
  private $error = inject(ErrorService);
  private $amplitude = inject(AmplitudeService);

  update = signal<AppUpdates.TUpdateResponse["update"]>("no_update");

  showClose = computed<boolean>(() => {
    return this.update() == "native";
  });

  status = signal<TStatus>("pending");
  downloadPercent = signal<number>(0);

  subscriptionDownloadStart: Subscription | undefined;
  subscriptionDownloadProgress: Subscription | undefined;
  subscriptionDownloadComplete: Subscription | undefined;
  subscriptionUpdateStart: Subscription | undefined;
  subscriptionUpdateComplete: Subscription | undefined;

  constructor() {
    this.$appUpdate.sUpdateAvailable.subscribe(update => {
      if (update == "no_update") {
        this.dismiss();
      }
      this.update.set(update);
    });
  }

  async dismiss() {
    await this.modalController.dismiss();
    this.$amplitude.track('MODAL_DISMISS', { modal: "app_updater" });
  }

  async onUpdate() {
    try {
      switch (this.update()) {
        case "mandatory_web":
          this.downloadBundle();
          this.$amplitude.track('APP_UPDATE_LIVE');
          break;
        case "mandatory_native":
        case "native":
          await this.goToStore();
          this.$amplitude.track('APP_UPDATE_FROM_STORE');
          break;
      }
    } catch (error) {
      this.$error.showToast({
        message: "Error updating app...",
        header: "Error",
        position: "top",
        duration: 2000,
      });
      this.$amplitude.track('APP_UPDATE_FAIL');
    }
  }

  async goToStore() {
    const appInfo = await App.getInfo();
    const appId = appInfo.id;
    await NativeMarket.openStoreListing({ appId, country: "AU" });
  }

  downloadBundle() {
    this.status.set("downloading");
    this.$appUpdate.downloadNextBundle();
    this.initSubscriptions();
  }

  initSubscriptions() {
    this.$appUpdate.sDownloadStart.subscribe(() => {
      this.status.set("downloading");
      this.$amplitude.track('APP_UPDATE_DOWNLOAD_START');
    });
    
    this.$appUpdate.sDownloadProgress.subscribe(percent => {
      this.downloadPercent.set(percent);
      this.$amplitude.track('APP_UPDATE_DOWNLOAD_PROGRESS');
    });

    this.$appUpdate.sDownloadComplete.subscribe(() => {
      this.status.set("downloaded");
      this.$amplitude.track('APP_UPDATE_DOWNLOAD_COMPLETE');
      setTimeout(() => {
        this.$appUpdate.installNextBundle();
      }, 500);
      this.$amplitude.track('APP_UPDATE_INSTALL_START');
    });

    this.$appUpdate.sUpdateStart.subscribe(() => {
      this.status.set("updating");
    });

    this.$appUpdate.sUpdateComplete.subscribe(() => {
      this.status.set("completed");
      this.destroySubscriptions();
      setTimeout(() => {
        this.dismiss();
      }, 500);
      this.$amplitude.track('APP_UPDATE_INSTALL_COMPLETE');
    });
  }

  destroySubscriptions() {
    if (this.subscriptionDownloadStart) this.subscriptionDownloadStart.unsubscribe();
    if (this.subscriptionDownloadProgress) this.subscriptionDownloadProgress.unsubscribe();
    if (this.subscriptionDownloadComplete) this.subscriptionDownloadComplete.unsubscribe();
    if (this.subscriptionUpdateStart) this.subscriptionUpdateStart.unsubscribe();
    if (this.subscriptionUpdateComplete) this.subscriptionUpdateComplete.unsubscribe();
  }

}