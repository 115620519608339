import { inject, Injectable, signal } from '@angular/core';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { SplashScreen } from '@capacitor/splash-screen'
import { BundleInfo, CapacitorUpdater } from '@capgo/capacitor-updater';
import packageJson from '../../../package.json';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, firstValueFrom, Subject } from 'rxjs';
import { User } from '@chemist2u/types-client/C2U/ParseObjects';
import { AppUpdates } from '@chemist2u/types-client/C2U/AppUpdates';
import { UiService } from './ui.service';

type DownloadInfo = {
  version: string;
  url: string;
  sessionKey: string;
};

@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {
  private http = inject(HttpClient);
  private $ui = inject(UiService);

  private readonly checkUpdateUrl = environment.production
    ? environment.appUpdatesServer + '/update?env=production'
    : environment.appUpdatesServer + '/update?env=staging';

  private nextUpdate = signal<AppUpdates.TUpdateResponse["update"]>("no_update");
  private nextDownload = signal<DownloadInfo | undefined>(undefined);
  private nextBundle = signal<BundleInfo | undefined>(undefined);

  private isBusy = signal<boolean>(false);

  // Events for web bundle download
  public sDownloadStart: Subject<void> = new Subject();
  public sDownloadProgress: Subject<number> = new Subject();
  public sDownloadComplete: Subject<void> = new Subject();
  public sDownloadFailed: Subject<void> = new Subject();

  // Events for web bundle install
  public sUpdateStart: Subject<void> = new Subject();
  public sUpdateComplete: Subject<void> = new Subject();
  public sUpdateFailed: Subject<void> = new Subject();

  public sUpdateAvailable: BehaviorSubject<AppUpdates.TUpdateResponse["update"]> = new BehaviorSubject<AppUpdates.TUpdateResponse["update"]>("no_update");
  
  init() {
    if (!Capacitor.isNativePlatform()) {
      console.warn("[AppUpdateService]", "skipped init, app cannot update on Web platform");
      return;
    }

    this.checkForUpdate();

    App.addListener("appStateChange", async state => {
      if (state.isActive) {
        await this.checkForUpdate();
        if (this.nextUpdate() == "web") {
          await this.downloadNextBundle();
        }
      } else {
        await this.installNextBundle();
      }
    });

    this.sUpdateAvailable.subscribe(update => {
      switch (update) {
        case "mandatory_native":
          this.$ui.showAppUpdate.next(false);
          break;
        case "mandatory_web":
          this.$ui.showAppUpdate.next(false);
          break;
        case "native":
          this.$ui.showAppUpdate.next(true);
          break;
        case "web":
        case "no_update":
        default:
          // Do Nothing
      }
    });
  }
  
  public async getCurrentVersions() {
    const onMobile = Capacitor.isNativePlatform();
    let version_name = packageJson.version;
    if (onMobile) {
      const appInfo = await App.getInfo()
      const current = await CapacitorUpdater.current();

      if (current.bundle.version != "builtin") {
        version_name = current.bundle.version;
      }
      const version_build = appInfo.version;
      const version_code = appInfo.build;
      return { version_name, version_build, version_code };
    }
    return {
      version_name,
      version_build: "",
      version_code: "",
    };
  }

  private async getUpdateParams(): Promise<AppUpdates.TUpdateParams> {
    const appInfo = await App.getInfo();
    const pluginVersion = await CapacitorUpdater.getPluginVersion();
    const deviceInfo = await Device.getInfo();
    const deviceId = await CapacitorUpdater.getDeviceId();
    const user = User.current();

    const { version_name, version_build, version_code } = await this.getCurrentVersions();

    const params: AppUpdates.TUpdateParams = {
      app_id: appInfo.id,
      device_id: deviceId.deviceId,
      platform: deviceInfo.platform,

      ...(user && {
        custom_id: user.id,
      }),
      version_name,
      version_build,
      version_code,
  
      version_os: deviceInfo.osVersion,
      plugin_version: pluginVersion.version,
    
      is_prod: environment.production,
      is_emulator: deviceInfo.isVirtual,
    };
    return params;
  }

  private async checkForUpdate(): Promise<void> {
    const nextUpdate = this.nextUpdate();
    if (nextUpdate != "no_update" || this.isBusy()) return;
    this.isBusy.set(true);
    
    const params: AppUpdates.TUpdateParams = await this.getUpdateParams();
    const APP_UPDATES_SERVER_KEY = environment.appUpdatesServerKey;

    const response = await firstValueFrom(
      this.http.post<AppUpdates.TUpdateResponse>(
        this.checkUpdateUrl,
        params,
        {
        headers: {
          "x-api-key": APP_UPDATES_SERVER_KEY,
        } 
      })
    );
    await this.handleUpdate(response);
    this.isBusy.set(false);
  }

  private async handleUpdate(response: AppUpdates.TUpdateResponse) {
    this.nextUpdate.set(response.update);
    switch (response.update) {
      case "mandatory_native":
        this.sUpdateAvailable.next("mandatory_native");
        break;
      case "native":
        this.sUpdateAvailable.next("native");
        break;
      case "mandatory_web":
        this.sUpdateAvailable.next("mandatory_web");
        this.queueDownload({
          version: response.version_name,
          url: response.bundle_url,
          sessionKey: response.session_key,
        });
        break;
      case "web":
        this.queueDownload({
          version: response.version_name,
          url: response.bundle_url,
          sessionKey: response.session_key,
        });
        break;
      case "no_update":
        // Do something?
        break;
      default:
        // Do something?
        break;
    }
  }

  private async queueDownload(bundle: DownloadInfo) {

    this.nextDownload.set({
      version: bundle.version,
      url: bundle.url,
      sessionKey: bundle.sessionKey,
    });
  }

  public async downloadNextBundle() {
    const bundle = this.nextDownload();
    if (bundle == undefined || this.isBusy()) return;
    this.isBusy.set(true);

    this.sDownloadStart.next();

    CapacitorUpdater.addListener("download", state => {
      setTimeout(() => {
        this.sDownloadProgress.next(state.percent);
      }, 100);
    });
    
    CapacitorUpdater.addListener("downloadComplete", state => {
      this.sDownloadComplete.next();
      this.nextDownload.set(undefined);
      this.queueInstall(state.bundle);
      CapacitorUpdater.removeAllListeners();
      this.isBusy.set(false);
    });

    CapacitorUpdater.addListener("downloadFailed", state => {
      this.sDownloadFailed.next();
      CapacitorUpdater.removeAllListeners();
      this.isBusy.set(false);
    });

    await CapacitorUpdater.download({
      url: bundle.url,
      version: bundle.version,
      sessionKey: bundle.sessionKey,
    });
  }

  private async queueInstall(bundle: BundleInfo) {

    this.nextBundle.set(bundle);
  }

  public async installNextBundle() {
    const nextBundle = this.nextBundle();
    if (nextBundle == undefined || this.isBusy()) return;
    this.isBusy.set(true);

    this.sUpdateStart.next();
    await SplashScreen.show();

    CapacitorUpdater.addListener("updateFailed", async state => {
      this.sUpdateFailed.next();
      CapacitorUpdater.removeAllListeners();
      await SplashScreen.hide();
      this.isBusy.set(false);
    });

    CapacitorUpdater.addListener("appReloaded", async () => {
      this.sUpdateComplete.next();
      this.nextBundle.set(undefined);
      CapacitorUpdater.removeAllListeners();
      await SplashScreen.hide();
      this.isBusy.set(false);
    });
    
    try { 
      await CapacitorUpdater.set(nextBundle);
    } catch (error) {
      console.error("[AppUpdateService]", "error installing bundle.", error);
      await SplashScreen.hide();
    }
  }
}