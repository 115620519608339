import { Component, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'app-splash-loader',
  templateUrl: './splash-loader.component.html',
  styleUrls: ['./splash-loader.component.scss'],
  standalone: true
})
export class SplashLoaderComponent {
  private $events = inject(EventsService);
  private router = inject(Router);

  public active = signal<boolean>(true);

  showSelf() {
    this.active.set(true);
  }

  hideSelf() {
    setTimeout(() => {
      this.active.set(false);
    }, 500)
  }

  goHome(): Promise<boolean> {
    return this.router.navigate(['/tabs/home']);
  }

  constructor() {
    this.$events.userLoggedIn.subscribe(() => {
      console.warn("[SplashLoaderComponent]", "Log In Trigger");
      this.showSelf();
      this.goHome();
    });

    this.$events.triggerAuthChangeStart.subscribe(() => {
      console.warn("[SplashLoaderComponent]", "Auth Change Start");
      this.showSelf();
    });

    this.$events.triggerAuthChangeComplete.subscribe(() => {
      console.warn("[SplashLoaderComponent]", "Auth Change Complete");
      this.hideSelf();
    });

    this.$events.userLoggedOut.subscribe(async () => {
      console.warn("[SplashLoaderComponent]", "Log Out Trigger");
      this.showSelf();
      await this.goHome();
      this.hideSelf();
    });
  }

}