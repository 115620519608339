import { Component, input, Input, Output, signal } from '@angular/core';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  standalone: true,
})
export class RadioComponent {
  public canToggle = input<boolean>(true);

  private _checked = signal(false);
  private _disabled = signal(false);

  @Input({ required: true }) set checked(value: boolean) {
    this._checked.set(value);
  }
  @Output() checkedChange = this._checked;

  @Input() set disabled(value: boolean) {
    this._disabled.set(value);
  }

  get checked() {
    return this._checked();
  }

  get disabled() {
    return this._disabled();
  }

  toggle() {
    if (!this.canToggle() || this.disabled) return;
    this._checked.set(!this.checked);
  }

}
