import { Component, Input, OnInit, computed, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonFooter, IonButton, IonButtons } from '@ionic/angular/standalone';
import { HeaderComponent } from 'src/app/components/navigation/header/header.component';
import { FooterComponent } from 'src/app/components/navigation/footer/footer.component';
import { ModalController, LoadingController } from '@ionic/angular/standalone';
import { AddressListComponent } from 'src/app/components/elements/address-list/address-list.component';
import { AddressType, PlaceAutocompleteResult, PlaceData } from '@googlemaps/google-maps-services-js';
import { CloudFunctionsService } from 'src/app/services/cloud.functions.service';
import { TitleComponent } from 'src/app/components/primitives/title/title.component';
import { SimpleButtonComponent } from 'src/app/components/primitives/simple-button/simple-button.component';
import { AddressDetailsComponent } from 'src/app/components/elements/address-details/address-details.component';
import { ButtonComponent } from 'src/app/components/primitives/button/button.component';
import { DeliveryMethodComponent } from 'src/app/components/elements/delivery-method/delivery-method.component';
import { InputComponent } from 'src/app/components/primitives/input/input.component';
import { IPharmacy, TOrderFulfillmentMethod, TSessionAddress, TSessionPrefFulfillmentMethod } from '@chemist2u/types-client/C2U/Interfaces';
import { StateService } from 'src/app/services/state.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { FetchService } from 'src/app/services/fetch.service';
import { CustomerAddress, Pharmacy } from '@chemist2u/types-client/C2U/ParseObjects';
import { AddressListViewComponent } from 'src/app/components/scaffolds/address-list/address-list.component';
import { AddressRowComponent } from 'src/app/components/elements/address-row/address-row.component';
import { GeolocationService } from 'src/app/services/geolocation.service';
import { PushService } from 'src/app/services/push.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { ErrorService } from 'src/app/services/error.service';
import { StepsContainerComponent } from 'src/app/components/scaffolds/steps-container/steps-container.component';
import C2U from '@chemist2u/types-client';

@Component({
  selector: 'app-set-address',
  templateUrl: './set-address.page.html',
  styleUrls: ['./set-address.page.scss'],
  standalone: true,
  imports: [AddressRowComponent, IonButtons, IonButton, IonFooter, IonContent, IonHeader, IonTitle, IonToolbar, CommonModule, FormsModule, HeaderComponent, FooterComponent, InputComponent, AddressListComponent, TitleComponent, SimpleButtonComponent, AddressDetailsComponent, ButtonComponent, DeliveryMethodComponent, AddressListViewComponent, StepsContainerComponent]
})
export class SetAddressPage implements OnInit {
  // =============================================================================
  // INJECT SERVICES
  // =============================================================================
  private $geolocation = inject(GeolocationService);
  private $error = inject(ErrorService);
  private $cloud = inject(CloudFunctionsService);
  private $state = inject(StateService);
  private $push = inject(PushService);
  private $fetch = inject(FetchService);
  private $amplitude = inject(AmplitudeService);
  

  // =============================================================================
  // IONIC CONTROLLERS
  // =============================================================================
  private modalController = inject(ModalController);
  private loadingController = inject(LoadingController);


  // =============================================================================
  // VARIABLES
  // =============================================================================
  public session = toSignal(this.$state.bSession);
  public finalAddress: TSessionAddress | undefined;
  public currentLocation = toSignal(this.$state.bCurrentLocation);
  public savedAddresses = signal<CustomerAddress[]>([]);
  public currentStep = signal(0);
  public addressSearchValue: string = '';
  public predictions = signal<PlaceAutocompleteResult[]>([]);
  public selectedAddress: PlaceAutocompleteResult | undefined = undefined;
  public placeDetails: PlaceData | undefined = undefined;
  public deliveryNotes: string = '';
  public orderFulfillmentMethods = signal<TOrderFulfillmentMethod[]>([]);
  public selectedFulfillmentMethod = toSignal(this.$state.bSelectedFulfillmentMethod);
  private explicitSelectedMethod = signal<TOrderFulfillmentMethod | undefined>(undefined);
  public pharmacyAddress = signal<Partial<IPharmacy> | undefined>(undefined);
  private pharmacyBracketModel = signal<C2U.Cloud.TgetPharmacyBracketModelResult | undefined>(undefined);

  public unitNumber = signal<string>('');
  public unitNumberChanged = (value: string | Date | number) => {
    this.unitNumber.set(value as string);
  };


  pageTitle = 'Order address';
  steps: string[] = [
    'Order address',
    'Order address',
    'Order address',
    // Add or remove steps as needed
  ];



  
  // =============================================================================
  // COMPUTED VARIABLES
  // =============================================================================
  
  // methodToPass is a computed variable that returns the selected method to pass to the next page
  public methodToPass = computed(() => {
    if (this.explicitSelectedMethod()) {
      return this.explicitSelectedMethod();
    }
    const findMethod = this.orderFulfillmentMethods().find(method => method.selectedMethod.method === this.selectedFulfillmentMethod()?.selectedMethod.method);
    if(findMethod) {
      return this.selectedFulfillmentMethod();
    }
    return this.orderFulfillmentMethods()[0];
  });

  // get customerAddresses from savedAddresses - TODO - why don't we just use savedAddresses?
  public customerAddresses = computed(() => {
    return this.savedAddresses();
  });

  // TODO - I don't think we need this anymore
  public preferredMethodOnSession = computed(() => {
    return this.session()?.prefFulfillmentMethod;
  });

  // isLastStep is a computed variable that returns true if the current step is the last step
  isLastStep = computed(() => this.currentStep() === this.steps.length - 1);





  // =============================================================================
  // INPUTS
  // =============================================================================

  // setCurrentStep input utilized when we need to enter into the page at a specific step
  @Input() set setCurrentStep(value: number) {

    if (value == 1) {
      this.nextStep();
    }

    if (value == 2) {
      this.navigateToStep(2);
    }
  }

  // setFinalAddress input utilised when we come in at step 2
  @Input() set setFinalAddress(value: TSessionAddress) {
    this.finalAddress = value;
    this.deliveryNotes = value.deliveryNote;
  }


  // =============================================================================
  // ANGULAR LIFECYCLE
  // =============================================================================
  async ngOnInit() {
    this.$geolocation.getCurrentPosition();
    this.updatePageTitle();
    this.$fetch.customerAdresses().then((addresses) => {
      this.savedAddresses.set(addresses);
    });
    this.setOrderFulfillmentMethods();



    if(this.finalAddress) {
      const pharmacyBracket = await this.$cloud.getPharmacyBracketModel(this.finalAddress);
      const pharmacyAddress = await this.$cloud.getPharmacyAddress(pharmacyBracket.pharmacyObjectId);
      this.pharmacyBracketModel.set(pharmacyBracket);
      this.pharmacyAddress.set(pharmacyAddress);
    }
  }


  // =============================================================================
  // METHODS
  // =============================================================================

  // gets order fulfillment methods for the pharmacy either from the cloud or from the state
  private async setOrderFulfillmentMethods(address?: TSessionAddress) {
    const orderFulfillmentMethods = address
      ? await this.$cloud.getOrderFulfillmentMethodsForPharmacy(address)
      : await this.$state.bAvailableFulfillmentMethods.getValue();
    this.orderFulfillmentMethods.set(orderFulfillmentMethods);
    return orderFulfillmentMethods;
  }

  // selectGeoAddress method to select the current location
  async selectGeoAddress() {
    const selectedAddress = this.currentLocation();
    const addressComponents = selectedAddress?.address_components || [];
    const postalCode = addressComponents.find(comp => comp.types.includes(AddressType.postal_code))?.long_name || '';
    this.$amplitude.track('ADDRESS_SELECTED_GEO', { postalCode });
    const searchValue = selectedAddress?.formatted_address || '';
    if (searchValue.length) {
      const prediction = await this.retrieveFirstPredictionGivenSearchValue(searchValue);
      await this.addressSelected(prediction);
    }
  }

  // selectSavedAddress method to select a saved address from new CustomerAddress table
  async selectSavedAddress(address: CustomerAddress) {
    const addressComponents = address.address.address_components || [];
    const postalCode = addressComponents.find(comp => comp.types.includes(AddressType.postal_code))?.long_name || '';
    this.$amplitude.track('ADDRESS_SELECTED_RECENT', { postalCode });
    const searchValue = address.address.formatted_address || '';
    const prediction = await this.retrieveFirstPredictionGivenSearchValue(searchValue);
    await this.addressSelected(prediction);
  }

  // searchForAddress method to search for an address given a search value
  // TODO - this has no debounce, so it will make a request for every keypress
  async searchForAddress(searchValue: string | Date | number) {
    this.$amplitude.track('ADDRESS_SEARCH', { searchTerm: searchValue as string });
    const value = searchValue as string;
    if (value.length < 3) {
      this.predictions.set([]);
      return;
    };

    const result = await this.$cloud.getGooglePlacesPredictions(value);
    const predictions = result.predictions;
    this.$amplitude.track(predictions.length > 0 ? 'ADDRESS_SEARCH_HAS_RESULTS' : 'ADDRESS_SEARCH_HAS_NO_RESULTS', { searchTerm: searchValue as string });
    this.predictions.set(predictions);
  }

  // retrieveFirstPredictionGivenSearchValue method to retrieve the first prediction given a search value
  async retrieveFirstPredictionGivenSearchValue(searchValue: string) {
    const result = await this.$cloud.getGooglePlacesPredictions(searchValue);
    return result.predictions[0];
  }

  // TODO - this feels really dumb, why not just use the signal or model
  assignDeliveryNotes(value: string | Date | number) {
    this.deliveryNotes = value as string;
  }

  methodSelected(method: TOrderFulfillmentMethod) {
    this.explicitSelectedMethod.set(method);
    const methodName = method.selectedMethod.method;
    this.$amplitude.track('ADDRESS_FULFILLMENT_METHOD', { methodName })
  }

  async searchAddressSelected(prediction: PlaceAutocompleteResult) {
    console.log("[SetAddressPage] searchAddressSelected", prediction);
    await this.addressSelected(prediction, true);
  }

  async addressSelected(prediction: PlaceAutocompleteResult, cameFromSearch: boolean = false) {
    // Handle address selection logic here
    this.selectedAddress = prediction;
    const placeResult = await this.$cloud.getGooglePlaceDetails(prediction.place_id);
    this.placeDetails = placeResult.result as PlaceData;
    console.log("[SetAddressPage] addressSelected", this.placeDetails);

    const addressComponents = this.placeDetails.address_components || [];
    const hasStreetNumber = addressComponents.some(comp => comp.types.includes(AddressType.street_number));
    const subpremise = addressComponents.find(comp => comp.types.includes(AddressType.subpremise))?.long_name || '';
    this.unitNumber.set(subpremise);

    if(!hasStreetNumber) {
      this.$error.showToast({
        message: "Please select a valid address with a street number",
        header: "Invalid address",
        position: "bottom",
        duration: 5000,
        swipeGesture: "vertical"
      });
      return;
    }

    if (cameFromSearch) {  
      const postalCode = addressComponents.find(comp => comp.types.includes(AddressType.postal_code))?.long_name || '';
      this.$amplitude.track('ADDRESS_SELECTED_SEARCH', { postalCode });
    }

    this.finalAddress = {
      ...this.finalAddress,
      deliveryToDoor: false,
      deliveryNote: this.deliveryNotes,
      aptSuiteFloor: '',
      businessName: '',
      selectedAddress: this.selectedAddress,
      address: this.placeDetails,
      atl: false,
    }

    const loading = await this.loadingController.create({
      message: 'Pharmacy check...',
      showBackdrop: true,
      duration: 2000
    });
    await loading.present();

    try {
      const methods = await this.setOrderFulfillmentMethods(this.finalAddress);
      this.$amplitude.track(methods.length > 0 ? 'ADDRESS_HAS_PHARMACY' : 'ADDRESS_HAS_NO_PHARMACY');
    } catch (error) {
      this.$amplitude.track('ADDRESS_HAS_NO_PHARMACY');
      throw error;
    }

    await loading.dismiss();
    this.nextStep();
  }

  async navigateToStep(targetStep: number) {
    this.currentStep.set(targetStep);
    this.updatePageTitle();
  }

  async nextStep() {
    const currentStep = this.currentStep();
    const nextStep = currentStep + 1;
    await this.navigateToStep(nextStep);
  }

  async previousStep() {
    if (this.currentStep() === 0) {
      this.dismiss();
      return;
    }
    await this.navigateToStep(this.currentStep() - 1);
  }

  async goToFirstStep() {
    await this.navigateToStep(1);
  }

  updatePageTitle() {
    this.pageTitle = this.steps[this.currentStep()];
  }

  async setDeliveryNotes() {
    const loading = await this.loadingController.create({
      message: 'Getting Pharmacy...',
      showBackdrop: true,
      duration: 2000
    });
    await loading.present();

    this.finalAddress = {
      ...this.finalAddress!,
      deliveryNote: this.deliveryNotes,
      aptSuiteFloor: this.unitNumber(),
    }
    await this.nextStep();
    this.$amplitude.track('ADDRESS_DELIVERY_NOTES', { deliveryNotes: this.deliveryNotes });
    const pharmacyBracket = await this.$cloud.getPharmacyBracketModel(this.finalAddress!);
    const pharmacyAddress = await this.$cloud.getPharmacyAddress(pharmacyBracket.pharmacyObjectId);
    this.pharmacyBracketModel.set(pharmacyBracket);
    this.pharmacyAddress.set(pharmacyAddress);

    await loading.dismiss();
  }

  async setAddress() {
    if (!this.session()) {
      return await this.dismiss();
    }

    const loading = await this.loadingController.create({
      message: 'Saving...',
      showBackdrop: true,
      duration: 2000
    });
    await loading.present();

    // const pharmacy = await await this.$cloud.getPharmacyBracketModel(this.finalAddress!);
    await this.$push.session(this.session()!, {
      ...this.session()!.attributes,
      address: this.finalAddress,
      pharmacy: this.pharmacyBracketModel(),
      prefFulfillmentMethod: this.methodToPass()?.selectedMethod.method === 'clickAndCollect' ? 'Standard' : this.methodToPass()?.selectedMethod.method! as TSessionPrefFulfillmentMethod,
      fulfillmentMethod: this.methodToPass()
    });
    this.$state.bSelectedFulfillmentMethod.next(this.methodToPass());

    const addressComponents = this.finalAddress?.address.address_components || [];
    const postalCode = addressComponents.find(comp => comp.types.includes(AddressType.postal_code))?.long_name || '';
    this.$amplitude.track('ADDRESS_SAVE', { postalCode });

    await loading.dismiss();
    await this.dismiss();
  }

  dismiss(data?: any) {
    this.$amplitude.track('MODAL_DISMISS_SET_ADDRESS');
    this.modalController.dismiss(data);
  }
}