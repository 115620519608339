export const LegacyAmplitudeEvents = {
	VIEW_CART: "User opened cart",
	CLOSE_CART: "User closed cart",
	CART_REMOVED_ITEM: "User removed item from cart",
	CART_REMOVED_ITEM_FAIL: "Error removing item from cart",
	CART_UPDATED: "User updated cart",
	CART_UPDATED_FAIL: "Error updating cart",
	CART_REMOVED_SCRIPT: "User removed script from cart",
	CART_INCREASED_QTY: "User increased item quantity",
	CART_REDUCED_QTY: "User decreased item quantity ",
	CART_OPEN_ITEM: "User opened item page",
	CART_CLOSE_ITEM: "User closed item page",
	CART_EMPTIED: "User emptied cart",
	ADD_TO_CART: "User added item to cart",
	VIEW_ITEM: "User viewed item details",
	TO_SET_ADDRESSS: "User clicked address",
	OPEN_WALLET: "User opened wallet",
	CLOSE_WALLET: "User closed wallet",
	OPEN_PAYMENT_FORM: "User opened add payment form",
	CLOSE_PAYMENT_FORM: "User closed add payment form",
	ADD_PAYMENT_METHOD_SUCCESS: "User successfully added payment method",
	ADD_PAYMENT_METHOD_FAIL_1: "User provided invalid payment details",
	ADD_PAYMENT_METHOD_FAIL_2: "User failed adding payment method",
	UPDATE_PAYMENT_METHOD: "User updated selected payment method",
	DELETE_PAYMENT_METHOD: "User removed a payment method",
	PROMO_OPEN: "User opened promotion modal",
	PROMO_CLOSE: "User closed promotion modal",
	PROMO_SELECT_SUCCESS: "User selected a promotion",
	PROMO_SELECT_FAIL: "Error adding promotion",
	PROMO_ADD_SUCCESS: "User successfully added promotion",
	PROMO_ADD_FAIL: "User provided an invalid promotion code",
	ADD_SCRIPT_SUBMIT: "User clicked 'Submit Script'",
	ADD_SCRIPT_SUCCESS: "User added script to cart",
	SELECT_MEDICAL_PROFILE: "User selected a medical profile",
	UPLOAD_IMAGE_START: "User clicked 'Upload Script'",
	UPLOAD_IMAGE_SELECT: "User selected an image",
	UPLOAD_IMAGE_SUCCESS: "User successfully uploaded image",
	UPLOAD_IMAGE_FAIL: "Error occured while uploading image",
	UPLOAD_SCRIPT_CANCEL: "User clicked 'Cancel'",
	ADD_MEDICAL_PROFILE_START: "User clicked 'Add medical profile'",
	UPDATE_MEDICAL_PROFILE_START: "User selected a medical profile to edit",
	MEDICAL_PROFILE_SUBMITTED: "User submitted medical profile",
	MEDICAL_PROFILE_UPDATED: "User edited existing medical profile",
	REVIEW_MEDICAL_PROFILE_FAIL: "Error submitting medical profile",
	CHECKOUT_EDIT_ADDRESS: "User opened address search",
	CHECKOUT_ADD_SCRIPT: "User clicked 'Add Script'",
	CHECKOUT_ADD_ITEM: "User clicked 'Add Item'",
	CHECKOUT_PROMO_REMOVED_SUCCESS: "User removed promotion",
	CHECKOUT_PROMO_REMOVED_FAIL: "Error removing promotion",
	CHECKOUT_SUBMIT_SUCCESS: "User checked out successfully",
	CHECKOUT_ERROR: "Error checking out",
	ITEM_SEARCH: "User searching for item...",
	ITEM_SEARCH_FAIL: "User searched and found no results",
	PRODUCTS_TO_CATEGORY: "User clicked category",
	PRODUCTS_TO_SUBCATEGORY: "User clicked sub-category",
	BACK_TO_CATEGORIES: "User returned to categories",
	ADDRESS_SEARCH_FAIL: "No addresses found for user's search",
	ADDRESS_SEARCHING: "User's search returned predictions",
	ADDRESS_SEARCH_SUCCESS: "User selected a predicted address",
	RECENT_ADDRESS_SELECT: "User selected a recent location",
	GEO_ADDRESS_SELECT: "User selected geo address",
	ADDRESS_SEARCH_CLOSE: "User closed address modal",
	TO_LOGIN: "User clicked 'Log In'",
	LOGOUT: "User logged out",
	TO_HELP: "User clicked 'Help'",
	TO_MEDICAL_PROFILE: "User clicked 'Medical Profiles'",
	TO_PERSONAL_INFO: "User clicked 'Personal Info'",
	TO_WALLET: "User clicked 'Wallet'",
	TO_SETTINGS: "User clicked 'Settings'",
	SIGNUP_SUBMIT_SUCCESS: "User signed up successfully",
	SIGNUP_SUBMIT_FAIL: "Error on sign up",
	SIGNIN_PHONE_NUMBER: "User entered phone number",
	SIGNIN_USER_FOUND: "User was found",
	SIGNIN_USER_NOT_FOUND: "User was not found",
	LOGIN_SUCCESS: "User logged in",
	LOGIN_FAIL: "User provided an incorrect password",
	PHARMACY_SEARCH_FAIL: "No pharmacy was found for provided address",
	PHARMACY_SEARCH_SUCCESS: "Set pharmacy for provided address",
	VIEW_ORDER: "User opened order details",
	VIEW_PAST_ORDER: "User opened past order details",
	CLOSE_ORDER_PAGE: "User closed order page",
	TO_HOME: "User opened Home tab",
	TO_BROWSE: "User opened Browse tab",
	TO_ORDERS: "User opened Orders tab",
	TO_ACCOUNT: "User opened Account tab",
	VIEW_TERMS_OF_SERVICE: "User opened the terms of service",
	VIEW_PRIVACY_POLICY: "User opened the privacy policy",
	GENERIC_BACK: "User clicked back",
	TO_PAYMENT_METHODS: "User opened payment methods",
	TO_COUPONS: "User opened coupons",
	TO_FORGOT_PASSWORD: "User clicked 'Forgot password'",
	ADD_SCRIPT_CTA: "User clicked add script button",
	UPDATE_PERSONAL_INFO: "User updated a personal info field",
	ADD_ANOTHER_SCRIPT: "User clicked 'Add Another'",
	
	SELECT_SCHEDULE_DELIVERY: "User opended schedule delivery",
	OPEN_DELIVERY_INSTRUCTIONS: "User opened delivery instructions",
	ANONYMOUS_OPEN_SIGNUP: "Anonymous user clicked 'Sign Up'",
	ANONYMOUS_USER_CLICKED_ON_UPLOAD_SCRIPT_BOX: "Anonymous user clicked on Upload Script",
	USER_CLICKED_ON_UPLOAD_SCRIPT_BOX: "User clicked on Upload Script tile",
	AUTHY_CODE_SENT: "2FA code sent",
	AUTHY_CODE_FAIL: "2FA code failed",
	AUTHY_CODE_SUCCESS: "2FA code success",
	USER_QR_LIBRARY: "User selected a QR code image from library",
	USER_QR_CAMERA: "User scanned a QR code image from camera",
	USER_INVALID_ERX_TOKEN: "User provided an invalid eRx token",
	USER_UPLOADED_ERX_TOKEN: "User provided a valid eRx token",

	CHAT_STARTED: "User sent a message in help chat",
	CHAT_CLOSED: "Help chat was closed",

// TODO ---------------------------------------------------------------------------------------------------------------------

	// OPEN_VIEW_SCRIPT: "User opened mini view script",
	// CLOSE_VIEW_SCRIPT: "User closed mini view script page",

// CONFUSED ABOUT ---------------------------------------------------------------------------------------------------------------------

	// ANONYMOUS_USER_CLICKED_ON_SCRIPT_TRACKER_BOX: "Anonymous user clicked on Open Script Tracker",
	// USER_CLICKED_ON_SCRIPT_TRACKER_BOX: "User clicked on Open Script Tracker tile",    
	// PENDING_SCRIPT_REMOVED: "User removed a pending script from the mini view script page",
	// UPLOAD_PRESCRIPTION: "User clicked Upload Prescription button",
	// USER_CLEAR_ERX_TOKEN: "User cleared an eRx token from a script item",
	// USER_NEXT_PENDING_SCRIPT: "User moved to the next script item",
	// USER_PREV_PENDING_SCRIPT: "User moved to the previous script item",
	// USER_COMPLETED_PENDING_SCRIPTS: "User provided eRx tokens for all script items",
	// USER_UPLOADED_PENDING_SCRIPT: "User provided an eRx token for a script item",
	// EDIT_MEDICATION_SAVE: "User clicked Save Updates",
	// CLOSE_EDIT_MEDICATION: "User closed edit medication modal",
	// TO_ADD_MEDICATIONS: "User clicked Add Medications",
	// PRESCRIPTION_ITEM_ERROR: "User encountered an error while building medication page",
	// NEW_MEDICATION_SELECT: "User selected an item from add medication page",
	// OPEN_MEDICATION_MENU: "User opened medication menu",
	// EDIT_MEDICATION: "User opened edit medication modal",

// IGNORE BELOW ---------------------------------------------------------------------------------------------------------------------

	// ANONYMOUS_SET_ADDRESS: "Anonymous user clicked 'Set Address'", // Doesn't apply

	// MEDICARE_WARNING: "User was shown the 'You haven't added a Medicare number' warning", // Doesn't apply
	// MEDICARE_NEXT: "User clicked next", // Doesn't apply
	// MEDICARE_ADD: "User clicked 'Add Medicare number'", // Doesn't apply

	// UPLOAD_IMAGE_REMOVE: "User removed an image", // -- Don't need --can't remove image
	// TO_EDIT_MEDICAL_PROFILE: "User clicked Edit Medical Profile", // Duplicate
	// USER_SELECTED_MEDICAL_PROFILE: "User selected a medical profile", // Duplicate
	// CHECKOUT_NO_CARD: "User clicked next without a payment method", // Don't need
	// CHECKOUT_EDIT_ADDRESS_DETAILS: "User opened additional address details", // Don't need
	// USER_TO_REVIEW: "User was navigated to review screen", // -- Don't need
	// TRY_ANOTHER_ADDRESS: "User clicked 'Try Another Address'", // -- Don't need
	// CART_TO_CHECKOUT: "User clicked checkout", // -- Don't need
	// ADD_SCRIPT_TYPE: "User selected script type", // -- Don't need Don't need Don't need
	// C2U_AGREE_HELP: "User clicked 'I have a paper script'", // -- Don't need
	// C2U_ESCRIPT_INTRODUCTION: "User was shown the eScript introduction", // -- Don't need
	// C2U_ESCRIPT_AGREE: "User clicked 'Got it'", // -- Don't need
	// CLICK_AND_COLLECT_FAKE_DOOR: "User clicked Fake Click and Collect", // Don't need

	// -- Don't need - Script Tracker
	// TO_SCRIPT_TRACKER: "User clicked 'Script tracker'",
	// ADD_SCRIPT_TRACKER_TO_CART: "User added item to cart via medication list",
	// REMOVE_SCRIPT_TRACKER_FROM_CART: "User removed item from cart via medication list",
	// DELETE_SCRIPT_TRACKER: "User clicked Delete Forever",
	// DELETE_MEDICATION_SAVE: "User removed medication from script tracker",
	// ADD_MEDICATION_SEARCHING: "User is searching for medications...",
	// ADD_MEDICATION_SEARCH_FAIL: "User's search returned no results",
	// ADD_MEDICATION_SEARCH_SUCCESS: "User's search returned results",
	// ADD_MEDICATION_SEARCH_ERROR: "User encountered an error in pbs search",
	// ADD_MEDICATION_ITEM_CLOSE: "User closed medication page",
	// ADD_MEDICATION_DRUG_SELECT: "User selected drug from search results",
	// ADD_MEDICATION_FORM_SELECT: "User selected drug form strength",
	// ADD_MEDICATION_DUPLICATE_ITEM: "User selected a medication already present in script tracker",
	// ADD_MEDICATION_SUCCESS: "User added an item to script tracker",
	

};