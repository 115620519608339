import { Component, computed, effect, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-password-rules',
  templateUrl: './password-rules.component.html',
  styleUrls: ['./password-rules.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class PasswordRulesComponent {
  public password = input<string>('');
  public validation = output<boolean>();

  constructor() {
    effect(() => {
      this.validation.emit(this.isValid());
    });
  }

  passwordRules = computed<{ satisfied: boolean, text: string }[]>(() => {
    const pass = this.password();
    const isAtLeastEightChars = pass.length >= 8;
    const hasLowercase = /[a-z]/.test(pass);
    const hasUppercase = /[A-Z]/.test(pass);
    const hasNumber = /\d/.test(pass);
    const hasSpecialChar = /[!@#$%^&*]/.test(pass);

    return [
      { satisfied: isAtLeastEightChars, text: "Contains at least eight characters" },
      { satisfied: hasLowercase, text: "Contains at least one lowercase letter" },
      { satisfied: hasUppercase, text: "Contains at least one uppercase letter" },
      { satisfied: hasNumber, text: "Contains at least one number" },
      { satisfied: hasSpecialChar, text: "Contains any of the following characters: ! &#64; # $ % ^ & *" },
    ];
  });

  isValid = computed<boolean>(() => {
    const rules = this.passwordRules();
    return rules.reduce((prev, curr) => prev && curr.satisfied, true);
  });

}