import { Component, computed, inject, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { StateService } from 'src/app/services/state.service';
import { IonIcon } from "@ionic/angular/standalone";
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  standalone: true,
  imports: [IonIcon]
})
export class BannerComponent {

  private $state = inject(StateService);
  private bInconvenienceMessage = toSignal(this.$state.bInconvenienceMessage);
  private bDismissInconvenienceMessage = toSignal(this.$state.bDismissedInconvenienceMessage);

  public message = computed(() => this.bInconvenienceMessage()?.message || "");
  public canDisplay = computed(() => {
    const inconvenienceMessage = this.bInconvenienceMessage();
    const dismissalTimestamp = this.bDismissInconvenienceMessage();

    // Condition 1: No message or message set to not show
    if (!inconvenienceMessage || !inconvenienceMessage.show) {
      return false;
    }

    // Condition 2: Message exists, set to show, and not dismissed
    if (!dismissalTimestamp) {
      return true;
    }

    // Condition 3: Message exists, set to show, and dismissed
    const now = new Date();
    return this.isDifferenceGreaterThanTwoHours(dismissalTimestamp, now);
  });



  constructor() {
    addIcons({ closeOutline });
   }


  close() {
    this.$state.bDismissedInconvenienceMessage.next(new Date());
  }

  private isDifferenceGreaterThanTwoHours(
    dateOrTimestamp1: Date | number,
    dateOrTimestamp2: Date | number
  ): boolean {
    const TWO_HOURS_IN_MS = 2 * 60 * 60 * 1000; // 2 hours in milliseconds

    // Convert inputs to timestamps in milliseconds
    const timestamp1 = dateOrTimestamp1 instanceof Date ? dateOrTimestamp1.getTime() : dateOrTimestamp1;
    const timestamp2 = dateOrTimestamp2 instanceof Date ? dateOrTimestamp2.getTime() : dateOrTimestamp2;

    // Calculate the absolute difference
    const difference = Math.abs(timestamp2 - timestamp1);

    return difference > TWO_HOURS_IN_MS;
  }

}
