import { Component, input } from '@angular/core';

@Component({
  selector: 'app-section-description',
  templateUrl: './section-description.component.html',
  styleUrls: ['./section-description.component.scss'],
  standalone: true,
})
export class SectionDescriptionComponent  {
  public title = input.required<string>();
  public description = input<string>();
}