<div class="address">
  <div class="top" (click)="editAddress()">
    <div class="icon">
        <ion-icon name="location" />
    </div>
    <div class="details">
      <div class="to">{{topLabel()}}</div>
      <div class="bottom">{{bottomLabel()}}</div>
    </div>
    @if(canEdit() && showEdit()) {
      <div class="edit">
        @if(fullEdit()) {
          <div class="label">Edit</div>
        }
        <ion-icon name="chevron-forward" />
      </div>
    }
   
  </div>
  @if(hasDeliveryInstructions() && !deliveryNoteEditMode()) {
    <div class="bot">
      @if(deliveryInstructions().length > 0) {
        <div class="instructions" (click)="editNotes()">{{deliveryInstructions()}}</div>
      } @else {
        <div class="instructions missing" (click)="editNotes()">Add delivery instructions</div>
      }
     
    </div>
  }
  
</div>
