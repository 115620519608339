import { CommonModule } from '@angular/common';
import { Component, Input, input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  imports: [CommonModule],
  standalone: true,
})
export class TooltipComponent implements OnInit {

  @Input({ required: true }) label!: string;
  @Input({ required: true }) triangleLeft!: number; // Add this line

  constructor() { }

  ngOnInit() { }

}
