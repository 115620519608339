import { Component, computed, input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  standalone: true,
})
export class ProgressBarComponent {
  mode = input<"standby" | "loader">("standby");
  progress = input<number>(100);

  percentage = computed<number>(() => {
    if (this.mode() == "standby") return 100;
    const prog = this.progress();
    if (prog < 0) return 0;
    if (prog > 100) return 100;
    return prog;
  });
}