import { Component, computed, inject, input, OnInit, output } from '@angular/core';
import { PriceComponent } from '../price/price.component';
import { SimpleButtonComponent } from '../../primitives/simple-button/simple-button.component';
import { TCartItem, TOrderItem } from '@chemist2u/types-client/C2U/Interfaces';
import { AlertController } from '@ionic/angular/standalone';
import { ImageComponent } from '../../primitives/image/image.component';

@Component({
  selector: 'app-product-row',
  templateUrl: './product-row.component.html',
  styleUrls: ['./product-row.component.scss'],
  standalone: true,
  imports: [PriceComponent, SimpleButtonComponent, ImageComponent]
})
export class ProductRowComponent implements OnInit {
  private alertController = inject(AlertController);

  public last = input<boolean>(false);
  public simple = input<boolean>(false);
  public product = input.required<TOrderItem | TCartItem>();
  public canEditQty = input<boolean>(false);
  public canRemove = input<boolean>(true);

  public editQty = output<void>();

  public productName = computed(() => this.product().name);
  public productPrice = computed(() => this.product().price);
  public productQuantity = computed(() => this.product().qty);

  public productThumbnailSrc: string | undefined = undefined;

  public remove = output<void>();
  public removingItem: boolean = false;

  ngOnInit(): void {
    this.productThumbnailSrc = this.getImageSrc();
  }

  async removeItem() {
    if (!this.canRemove() || this.removingItem) return;
    this.removingItem = true;
    const alert = await this.alertController.create({
      header: 'Remove item',
      message: 'Are you sure',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => {
            this.removingItem = false;
          }
        }, {
          text: 'Yes',
          handler: () => {
            this.removingItem = false;
            this.remove.emit();
          }
        }
      ]
    });
    alert.present();
  }

  editClick() {
    if (!this.canEditQty()) return;
    this.editQty.emit();
  }

  public getImageSrc() {
    if (this.product().featuredImageThumb) {
      //@ts-ignore
      return this.product().featuredImageThumb.url();
    } else if (this.product().featuredImage) {
      //@ts-ignore
      return this.product().featuredImage.url();
    } else {
      return "assets/images/item-placeholder.svg";
    }
  }
}