import { Component, inject, OnInit, input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonContent, IonHeader, IonFooter, IonTitle, IonToolbar, IonItemOptions, IonItemOption, IonItem, IonItemSliding, IonList, IonRefresher, IonRefresherContent, ModalController, LoadingController } from '@ionic/angular/standalone';
import { TitleComponent } from 'src/app/components/primitives/title/title.component';
import { HeaderComponent } from 'src/app/components/navigation/header/header.component';
import { SimpleButtonComponent } from 'src/app/components/primitives/simple-button/simple-button.component';
import { ErrorService } from 'src/app/services/error.service';
import { PaymentRowComponent } from 'src/app/components/elements/payment-row/payment-row.component';
import { ButtonComponent } from 'src/app/components/primitives/button/button.component';
import { StateService } from 'src/app/services/state.service';
import { CloudFunctionsService } from 'src/app/services/cloud.functions.service';
import { environment } from 'src/environments/environment';
import { ICardEway } from '@chemist2u/types-client/C2U/Interfaces';
import { FooterComponent } from 'src/app/components/navigation/footer/footer.component';
import { User } from '@chemist2u/types-client/C2U/ParseObjects';
import { PushService } from 'src/app/services/push.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';

declare var Square: any;

@Component({
  selector: 'app-add-card',
  templateUrl: './add-card.page.html',
  styleUrls: ['./add-card.page.scss'],
  standalone: true,
  imports: [PaymentRowComponent, ButtonComponent, SimpleButtonComponent, TitleComponent, HeaderComponent, FooterComponent, IonRefresher, IonRefresherContent, IonFooter, IonItemOptions, IonItemOption, IonItem, IonItemSliding, IonList, IonContent, IonHeader, IonTitle, IonToolbar, CommonModule, FormsModule]
})
export class AddCardPage implements OnInit {
  private modalController = inject(ModalController);
  private loadingController = inject(LoadingController);
  private $cloud = inject(CloudFunctionsService);
  private $push = inject(PushService);
  private $state = inject(StateService);
  private $error = inject(ErrorService);
  private $amplitude = inject(AmplitudeService);

  pushToCart = input<boolean>(false);
  requiredInp = input<boolean>(false, {
    alias: 'required'
  })

  public isLoading = signal<boolean>(true);
  private user = signal<User | undefined>(undefined);
  private card = signal<any>(undefined);

  // cardStyles
  public cardStyles = {
    '.input-container': {
      borderColor: '#97A6BD',
      borderRadius: '6px',
    },
    '.input-container.is-focus': {
      borderColor: '#4E6887',
    },
    '.input-container.is-error': {
      borderColor: '#FF1675',
    },
    '.message-text': {
      color: '#97A6BD',
    },
    '.message-icon': {
      color: '#97A6BD',
    },
    '.message-text.is-error': {
      color: '#FF1675',
    },
    '.message-icon.is-error': {
      color: '#FF1675',
    },
    input: {
      backgroundColor: '#FFFFFF',
      color: '#003E63',
      fontFamily: 'helvetica neue, sans-serif',
    },
    'input::placeholder': {
      color: '#D2D8E2',
    },
    'input.is-error': {
      color: '#ff1600',
    },
  };

  async ngOnInit() {
    this.$amplitude.track('MODAL_SHOW_ADD_CARD');
    this.user.set(this.$state.bUser.getValue());
    const payments = Square.payments(environment.sqAppID, environment.sqLocationID);
    const res = await payments.card({ style: this.cardStyles });
    this.card.set(res);
    try {
      await this.card().attach('#addCardPage-cardContainer');
    } catch (error) {
      console.error(error);
    }
    this.isLoading.set(false);
  }

  dismiss() {
    if (this.requiredInp()) return;
    this.modalController.dismiss();
    this.$amplitude.track('MODAL_DISMISS_ADD_CARD');
  }

  async addCard() {
    const user = this.user();
    if (user == undefined) return;
    const loader = await this.loadingController.create();
    try {
      await loader.present();
      this.isLoading.set(true);
      const result = await this.card().tokenize();
      if (result.status !== 'OK') throw new Error();
      const cardResult = await this.$cloud.createSquareCardToken({
        nonce: result.token,
        customerid: user.id,
      });
      
      if (cardResult == undefined) {
        this.$amplitude.track('CARD_TOKENIZATION_FAIL');
        throw Error();
      }

      const card: ICardEway = {
        expMonth: cardResult.card.expMonth,
        expYear: cardResult.card.expYear,
        brand: cardResult.card.cardBrand,
        last4: cardResult.card.last4,
        squareToken: cardResult.card.id,
        user: user
      };

      await this.$push.newCard(card).catch(e => {
        this.$amplitude.track('CARD_CREATE_FAIL');
        throw e;
      });

      this.$amplitude.track('CARD_CREATE_SUCCESS');
      this.modalController.dismiss(undefined, "close");
      this.$amplitude.track('MODAL_DISMISS_ADD_CARD');
    } catch (e: any) {
      console.error(e);
      this.$error.showToast({
        message: "Error adding new card",
        header: "Error",
        position: "top",
        duration: 2000,
      });
      this.$error.handleParseError(e);
    } finally {
      this.isLoading.set(false);
      loader.dismiss();
    }
  }
}