import { AfterViewInit, ChangeDetectionStrategy, Component, effect, ElementRef, inject, input, output, signal, ViewChild } from '@angular/core';
import { AnimationController } from '@ionic/angular/standalone';
import { ContentHeaderComponent } from '../content-header/content-header.component';

@Component({
  selector: 'app-steps-container',
  templateUrl: './steps-container.component.html',
  styleUrls: ['./steps-container.component.scss'],
  standalone: true,
  imports: [ContentHeaderComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepsContainerComponent implements AfterViewInit {
  @ViewChild('stepsContainer', { static: true }) stepsContainer!: ElementRef;

  private animationCtrl = inject(AnimationController);

  public step = input.required<number>();
  public navigationEnd = output();

  private currentStep = signal<number>(0);

  constructor() {
    effect(() => {
      const newStep = this.step();
      this.navigateToStep(newStep);
    });
  }

  ngAfterViewInit() {
    this.setStepStyles();
    this.configureFocus();
    this.configureHeights();
  }

  containerElement(): HTMLElement {
    return this.stepsContainer.nativeElement as HTMLElement;
  }

  stepElements(): HTMLElement[] {
    return Array.from(this.containerElement().children) as HTMLElement[];
  }

  private setStepStyles() {
    const stepWidth = 100 / this.stepElements().length;
    this.containerElement().style.width = `${100 * this.stepElements().length}%`;
    this.stepElements().forEach((step: HTMLElement) => {
      step.style.width = `${stepWidth}%`;
    });
  }

  private configureFocus() {
    const targetStep = this.currentStep();
    this.stepElements().forEach((step: HTMLElement, index: number) => {
      const focusableElements = step.querySelectorAll(
        'input, button, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      focusableElements.forEach(el => {
        (el as HTMLElement).tabIndex = targetStep == index ? 0 : -1;
      });
      if (focusableElements.length > 0 && targetStep == index) {
        (focusableElements[0] as HTMLElement).focus();
      }
    });
  }

  private configureHeights() {
    this.stepElements().forEach((el, index) => {
      if (this.currentStep() == index) {
        el.scrollTop = 0;
      }
      el.style.maxHeight = '100%';
      el.style.overflowY = 'auto';
    });
  }

  private async navigateToStep(targetStep: number) {
    if (targetStep < 0 || targetStep >= this.stepElements().length || targetStep === this.currentStep()) return;

    const stepWidth = 100 / this.stepElements().length;
    const isAdjacent = Math.abs(targetStep - this.currentStep()) === 1;

    // Reset scroll in tab we are leaving
    this.stepElements()[this.currentStep()].scrollTop = 0;

    if (isAdjacent) {
      // Slide animation for adjacent steps
      const slideAnimation = this.animationCtrl.create()
        .addElement(this.containerElement())
        .duration(300)
        .easing('ease-out')
        .fromTo('transform',
          `translateX(-${(this.currentStep()) * stepWidth}%)`,
          `translateX(-${(targetStep) * stepWidth}%)`
        );

      await slideAnimation.play();
    } else {
      // Fade out
      const fadeOutAnimation = this.animationCtrl.create()
        .addElement(this.containerElement())
        .duration(150)
        .fromTo('opacity', '1', '0');

      // Prepare the position change animation (but don't play it yet)
      const positionAnimation = this.animationCtrl.create()
        .addElement(this.containerElement())
        .duration(0) // Instant change
        .fromTo('transform',
          `translateX(-${(this.currentStep()) * stepWidth}%)`,
          `translateX(-${(targetStep) * stepWidth}%)`
        );

      // Fade in
      const fadeInAnimation = this.animationCtrl.create()
        .addElement(this.containerElement())
        .duration(150)
        .fromTo('opacity', '0', '1');

      // Play the animations in sequence
      await fadeOutAnimation.play();
      await positionAnimation.play();
      await fadeInAnimation.play();
    }
    
    this.currentStep.set(targetStep);
    this.configureFocus();
    this.configureHeights();
    this.navigationEnd.emit();
  }
}