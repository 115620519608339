import { Component, input, output } from '@angular/core';
import { RadioComponent } from '../../primitives/radio/radio.component';

@Component({
  selector: 'app-reschedule-time',
  templateUrl: './reschedule-time.component.html',
  styleUrls: ['./reschedule-time.component.scss'],
  standalone: true,
  imports: [RadioComponent]
})
export class RescheduleTimeComponent  {

    public id = input.required<string>();
    public label = input.required<string>();
    public selected = input<boolean>(false);
    public last = input<boolean>(false);
    public selectEvent = output<string>();

    public select() {
        this.selectEvent.emit(this.id());
    }
}