<div class="header">
  <div class="top">
    <div class="label">{{title()}}</div>
    @if(action()) {
      <app-simple-button [border]="true" icon="add-outline" color="tint" (click)="actionClicked()" [label]="actionLabel()" />
    }
  </div>
  @if(description()) {
    <div class="description">{{description()}}</div>
  }
</div>