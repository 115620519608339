<div class="input" [class.focused]="isFocused()" [class.disabled]="isDisabled()">
  <div class="top">
    @if(label()) {
      <label>{{label()}}@if(isRequired()){<span class="required">*</span>}</label>
    }
    @if(hasAction()) {
      <div class="action" (click)="action()">
        @if(actionLabel()) {
          <p>{{actionLabel()}}</p>
        }
        @if(actionIcon()) {
          <ion-icon [name]="actionIcon()"></ion-icon>
        }
      </div>
    }
  </div>
 
  @if (help()) {
    <p class="help">{{ help() }}</p>
  }
  
  <div [ngClass]="containerClass()">
    
    @if(icon() && !customIcon()) {
      <div class="icon" (click)="clickedIcon($event)">
        <ion-icon [name]="icon()"></ion-icon>
      </div>
    }
    @if(icon() && customIcon()) {
      <div class="icon" (click)="clickedIcon($event)">
        <ion-icon [src]="'assets/icons/'+icon()+'.svg'" />
      </div>
    }
    <input
    [autocomplete]="autocompleteValue()"
    #inputElement
    [type]="inputType"
    [value]="displayValue()"
    (input)="onInput($event)"
    (blur)="onBlur()"
    (focus)="onFocus()"
    [attr.disabled]="isDisabled() ? '' : null"
    [attr.required]="isRequired() ? '' : null"
    [attr.placeholder]="placeholder()"
    [attr.step]="step()"
    [attr.min]="min"
    [attr.max]="max"
  >
  @if(showClearButton()) {
    <button class="clear-button" (click)="clear()" (touchstart)="onClearTouchStart($event)"><ion-icon name="close-circle-outline"></ion-icon></button>
  }
  </div>
  @if(showError() || errorPadding()) {
    
    @if(externalError()) {
      <div class="error-message">{{externalError()}}&nbsp;</div>
    } @else {
      <div class="error-message">{{errorMessage()}}&nbsp;</div>
    }
  }

  
</div>