<div class="row" [class.simple]="simple()" [class.last]="last()" [class.info]="info()">
  <div class="icon">
    <img src="assets/icons/icon-prescription.svg" />
  </div>
  <div class="details">
    <div class="label" [title]="productName()">{{ productName() }}</div>
    @if (price()) {
      @if (!simple() && isPriced()) {
        <div class="price">
          <app-price [value]="productPrice()" />
          @if(includesPostage()) {
            <div class="label">(inc. postage)</div>
          }
        </div>
      } @else {
        @if(isPriced()) {
          <div class="price">
            <label>{{productPrice() | currency}}</label>
          </div>
        } @else {
          <div class="price">
            <label>Price pending</label>
          </div>
        }
        
      }
    }
  </div>
  @if (!simple() && canRemove()) {
    <div class="actions">
      <app-simple-button (click)="removeScript()" icon="trash-bin-outline" />
    </div>
  }
  @if(canReorder()) {
    <div class="actions">
      <app-simple-button (click)="reorder()" size="small" [border]="true" label="Reorder" />
    </div>
  }
</div>
@if(!isPriced()) {
  <app-info-bubble label="We need to check the price of this medication with our pharmacy partner." />
}
@if (info()) {
  <app-info-bubble label="You’ll receive a payment link for this item when stock is available. " />
}
