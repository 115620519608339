import { Component, output } from '@angular/core';
import { SimpleButtonComponent } from 'src/app/components/primitives/simple-button/simple-button.component';

@Component({
  selector: 'app-prescriptions-empty',
  templateUrl: './prescriptions-empty.component.html',
  styleUrls: ['./prescriptions-empty.component.scss'],
  standalone: true,
  imports: [SimpleButtonComponent]
})
export class PrescriptionsEmptyComponent {

}