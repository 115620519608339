import { Component, computed, input, output, signal } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss'],
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CounterComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: CounterComponent
    }
  ]
})
export class CounterComponent implements ControlValueAccessor, Validator {
  public value = signal<number>(1);
  public touched = signal<boolean>(false);
  public disabled = signal<boolean>(false);
  public label = input<string | undefined>(undefined);
  public size = input<"regular" | "medium" | "small">("regular");
  public min = signal<number>(1);
  public max = signal<number>(Infinity);

  public inc = output<void>();
  public dec = output<void>();

  public disabledDecrease = computed(() => this.disabled() || this.value() == this.min());
  public disabledIncrease = computed(() => this.disabled() || this.value() == this.max());

  onInc() {
    if (this.disabled() || this.value() == this.max()) return;
    this.value.update(v => v + 1);
    this.inc.emit();
    this.onChange(this.value());
  }

  onDec() {
    if (this.disabled() || this.value() == this.min()) return;
    this.value.update(v => v - 1);
    this.dec.emit();
    this.onChange(this.value());
  }

  // Form Control

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: number) {
    this.value.set(value);
  }
  
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  markAsTouched() {
    if (!this.touched()) {
      this.onTouched();
      this.touched.set(true);
    }
  }

  setDisabledState?(disabled: boolean) {
    this.disabled.set(disabled);
  }
  
  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const errors: any = {};
    const min = this.min();
    const max = this.max();
    if (value != undefined) {
      if (min != undefined && min > value) {
        errors['min'] = {
          min,
          actual: value
        };
      }
      if (max != undefined && value > max) {
        errors['max'] = {
          max,
          actual: value
        };
      }
    }
    if (Object.keys(errors).length) {
      return errors;
    }
    return null;
  }
}