import { Component, computed, input } from '@angular/core';
import { MedicalProfileInfoRowComponent } from './medical-profile-info-row/medical-profile-info-row.component';
import { MedicalProfileUserBubbleComponent } from './medical-profile-user-bubble/medical-profile-user-bubble.component';
import { ICustomerMedicalProfile } from '@chemist2u/types-client/C2U/Interfaces';

@Component({
  selector: 'app-medical-profile-header',
  templateUrl: './medical-profile-header.component.html',
  styleUrls: ['./medical-profile-header.component.scss'],
  standalone: true,
  imports: [MedicalProfileInfoRowComponent, MedicalProfileUserBubbleComponent]
})
export class MedicalProfileHeaderComponent {

  public padding = input<boolean>(true);
  public border = input<boolean>(true);
  public medicalProfile = input.required<Parse.Object.ToJSON<ICustomerMedicalProfile> & Parse.JSONBaseAttributes>();

  public name = computed(() => this.medicalProfile().name!);
  
  public concessionPresent = computed(() => this.medicalProfile().concession_card_number);
  public safetyNetPresent = computed(() => this.medicalProfile().safetyNet);
}