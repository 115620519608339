import { Component, signal } from '@angular/core';
import { IonSpinner } from '@ionic/angular/standalone';

@Component({
  selector: 'app-loading-view',
  templateUrl: './loading-view.component.html',
  styleUrls: ['./loading-view.component.scss'],
  standalone: true,
  imports: [IonSpinner]
})
export class LoadingViewComponent {
  label = signal<string>("Loading ...");
}