import { CommonModule } from '@angular/common';
import { Component, computed, input, output } from '@angular/core';
import { CardEway } from '@chemist2u/types-client/C2U/ParseObjects';
import { IonIcon } from "@ionic/angular/standalone";
import { addIcons } from 'ionicons';
import { chevronForward, trashBinOutline } from 'ionicons/icons';
import { ImageComponent } from '../../primitives/image/image.component';

@Component({
  selector: 'app-payment-row',
  templateUrl: './payment-row.component.html',
  styleUrls: ['./payment-row.component.scss'],
  standalone: true,
  imports: [CommonModule, IonIcon, ImageComponent]
})
export class PaymentRowComponent {

  card = input<CardEway>();
  last = input<boolean>(false);
  mode = input<"select" | "delete" | "none">("none");
  nopadding = input<boolean>(false);

  delete = output();

  cardBrandIcon = computed<string>(() => {
    switch (this.card()?.brand) {
      case "MASTERCARD":
        return 'card-mastercard';
      case "VISA":
        return 'card-visa';
      case "AMERICAN_EXPRESS":
        return 'card-amex';
      default:
        return 'card-mastercard';
    }
  });

  constructor() {
    addIcons({ chevronForward, trashBinOutline });
  }

  deleteCard(event: MouseEvent) {
    event.stopPropagation();
    this.delete.emit();
  }

}