import { Component, inject } from '@angular/core';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { SetupService } from './services/setup.service';
import { SplashLoaderComponent } from './components/elements/splash-loader/splash-loader.component';
import { GeolocationService } from './services/geolocation.service';
import { NativeService } from './services/native.service';
import { PushNotificationsService } from './services/push-notifications.service';
import { AppUpdateService } from './services/app-update.service';
import { GlobalTimingService } from './services/global-timing.service';
import { MiscService } from './services/misc.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonApp, IonRouterOutlet, SplashLoaderComponent],
})
export class AppComponent {
  private $setup = inject(SetupService);
  private $native = inject(NativeService);
  private $pushNotifications = inject(PushNotificationsService);
  private $geolocation = inject(GeolocationService);
  private $appUpdate = inject(AppUpdateService);
  private $globalTiming = inject(GlobalTimingService);
  private $misc = inject(MiscService);

  constructor() {
    this.$setup.init();
    this.$native.init();
    this.$pushNotifications.init();
    this.$geolocation.init();
    this.$appUpdate.init();
    this.$globalTiming.init();
    this.$misc.init();
  }
}
