<ion-header>
  <app-header>
    @if (showClose()) {
      <app-simple-button (click)="dismiss()" size="large" icon="close" slot="start" />
    }
    <app-title slot="main" size="medium" title="App Update" />
  </app-header>
</ion-header>

<ion-content>
  <div class="content">
    <div class="d-f align-center justify-center">
      <app-image [local]="true" [isIcon]="true" name="truck_black" />
    </div>
    <div>
      <h3>App Update Required!</h3>
      <p>We have added new features and fixed some bugs to make your experience seamless.</p>
    </div>
    <div class="mt-20">
      @if (update() != "mandatory_web" || status() == "pending") {
        <app-button (click)="onUpdate()" label="Update App"></app-button>
        @if (showClose()) {
          <app-simple-button class="mt-10" (click)="dismiss()" label="Cancel"></app-simple-button>
        }
      } @else {
        <ng-container *ngTemplateOutlet="downloadTracker"></ng-container>
      }
    </div>
  </div>
</ion-content>


<ng-template #downloadTracker>
  @switch (status()) {
    @case ("downloading") {
      <div class="d-f justify-space-between align-center">
        <div>Downloading...</div>
        <div>{{ downloadPercent() }}%</div>
      </div>
      <app-progress-bar class="mt-10" mode="loader" [progress]="downloadPercent()" />
    }
    @case ("downloaded") {
      Checking files...
    }
    @case ("updating") {
      Installing...
    }
    @case ("completed") {
      Wrapping things up...
    }
  }
</ng-template>