@if(canDisplay()) {
  <div class="row">
    <div class="label" (click)="showPopover($event)" [class.title]="title()">{{label()}} @if(message()) { 
      <ion-icon name="information-circle-outline" />
    } </div>
    @if(isReady() || forceValueDisplay()) {
      <div class="value" [class.discount]="isDiscount()">{{ ((isDiscount() ? -1 : 1) * value())| currency }}</div>
    } @else {
      <div class="value" [class.discount]="isDiscount()">Price pending</div>
    }
    
  </div>
}
