import { AsyncPipe, CurrencyPipe } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { EncryptedLabelComponent } from '../encrypted-label/encrypted-label.component';
import { CartTotalsRowComponent } from './cart-totals-row/cart-totals-row.component';
import { StateService } from 'src/app/services/state.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { InfoBubbleComponent } from '../info-bubble/info-bubble.component';
import { Cart } from '@chemist2u/types-client/C2U/ParseObjects/index.client';
import C2U from '@chemist2u/types-client';
import { TitleComponent } from '../../primitives/title/title.component';

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

@Component({
  selector: 'app-cart-totals',
  templateUrl: './cart-totals.component.html',
  styleUrls: ['./cart-totals.component.scss'],
  standalone: true,
  imports: [CurrencyPipe, EncryptedLabelComponent, CartTotalsRowComponent, InfoBubbleComponent, AsyncPipe, TitleComponent]
})
export class CartTotalsComponent {
  //TODO - This needs to be global as it is used in multiple places
  private weightLossExceptionItems: C2U.Cloud.TWeightLossItems[] = ['ozempic', 'semaglutide', 'wegovy', 'rybelsus', 'mounjaro', 'tirzepatide'];


  //Services
  private $state = inject(StateService);


  //State Objects
  public session = toSignal(this.$state.bSession);
  public allocatedPharmacy = toSignal(this.$state.bAllocatedPharmacy);


  //Inputs
  public cart = input<Cart>();



  //Computed values
  public totals = computed(() => {
    const cart = this.cart();
    if (!cart) return undefined;
    return cart.totals;
  });

  public totalsList = computed(() => {
    const cart = this.cart();
    if (!cart) return [];
    return cart.totalsList;
  });


  public fulfillmentMethod = computed(() => {
    return this.cart()?.fulfillmentDetails;
  });

  public doesCartHaveWeightLossScriptItems = computed(() => {
    const prescriptions = this.cart()?.prescriptions || [];
    return prescriptions?.some(prescription =>
      prescription.items.some(item =>
        this.weightLossExceptionItems.some(ozempicItem =>
          item.productName.toLowerCase().includes(ozempicItem.toLowerCase())
        )
      )
    );
  });


  public hasUnpricedItems = computed(() => {
    return !this.totals()?.total.isReady;
  });

  public hasPricedButPendingItems = computed(() => {
    const prescriptions = this.cart()?.prescriptions || [];
    const prescriptionItems = prescriptions.flatMap(prescription => prescription.items);

    const hasPendingItems = prescriptionItems.some(
      item => item.productStatus === 'Pending'
    );

    const allItemsArePriced = prescriptionItems.every(
      item => item.productPrice !== undefined && item.productPrice !== 0
    );

    return hasPendingItems && allItemsArePriced;
  });


  public deliveryString = computed(() => {
    if (this.fulfillmentMethod()?.selectedMethod.method === 'clickAndCollect') {
      return 'Collect from ' + this.allocatedPharmacy()?.address?.suburb;
    }

    if (this.fulfillmentMethod()?.selectedMethod.method === 'Standard') {
      const components: AddressComponent[] = this.session()!.address!.address.address_components;
      const locality = components.find(comp => comp.types.includes('locality'))?.long_name || '';
      return 'Delivery to ' + locality;
    }

    if (this.fulfillmentMethod()?.selectedMethod.method === 'OnDemand') {
      const components: AddressComponent[] = this.session()!.address!.address.address_components;
      const locality = components.find(comp => comp.types.includes('locality'))?.long_name || '';
      return 'Delivery to ' + locality;
    }

    if (this.fulfillmentMethod()?.selectedMethod.method === 'Postal') {
      return 'Postal Delivery';
    }

    return 'Delivery';
  });
}