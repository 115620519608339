import { Component, input, output } from '@angular/core';

@Component({
  selector: 'app-reschedule-day',
  templateUrl: './reschedule-day.component.html',
  styleUrls: ['./reschedule-day.component.scss'],
  standalone: true,
})
export class RescheduleDayComponent {
  public day = input.required<string>();
  public date = input.required<string>();
  public selected = input<boolean>(false);
  public changeDate = output<void>();

  public change() {
    this.changeDate.emit();
  } 
}