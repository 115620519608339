<div class="header" [class.border]="border()" [class.faded]="!isLight()">
  <div class="top">
    <div class="start">
      <ng-content select="[slot=start]"></ng-content>
    </div>
    <div class="main">
      <ng-content select="[slot=main]"></ng-content>
    </div>
    <div class="end">
      <ng-content select="[slot=end]"></ng-content>
    </div>
  </div>
  @if(isExtra()) {
    <div class="extra">
      <ng-content></ng-content>
    </div>
  }
  
</div>