<ion-header>
  <app-header background="faded">
    <app-simple-button (click)="dismiss()" size="large" icon="close" slot="start" />
    <app-title slot="main" size="small" title="Express courier options" />
  </app-header>
</ion-header>

<ion-content>
  <div class="content">
    <div class="title">Schedule delivery</div>
    <div class="hlist">
      @for(day of formattedShifts; track $index) {
        <app-reschedule-day [day]="day.day" [date]="day.date" (changeDate)="changeDate($index)" [selected]="selectedDate?.day === day.day" />
      }
    </div>
    <div class="vlist">
      @for(shift of showshifts; track $index) {
        <app-reschedule-time [last]="$last" [selected]="selectedShiftId === shift.id" [id]="shift.id" (selectEvent)="onSelection($event)" [label]="shift.shiftString!" />
      }
    </div>
  </div>
</ion-content>


<ion-footer>
  <app-footer>
    <app-button (clicked)="change()" size="large" label="Update" />
  </app-footer>
</ion-footer>