import { inject, Injectable } from '@angular/core';
import { User } from '@chemist2u/types-client/C2U/ParseObjects';
import { EventsService } from './events.service';
import { Dialog } from '@capacitor/dialog';
import { BiometricsService } from './biometrics.service';
import { UiService } from './ui.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private $ui = inject(UiService);
  private $events = inject(EventsService);
  private $biometrics = inject(BiometricsService);
  private $amplitude = inject(AmplitudeService);

  async showLogin() {
    this.$amplitude.track('AUTH_LOGIN_START');
    const canLogInWithBiometrics = await this.canLogInWithBiometrics();
    if (canLogInWithBiometrics) {
      try {
        await this.logInWithBiometrics();
        return;
      } catch (error) {
        throw error;
      } 
    }
    this.$ui.showLogin.next();
  }

  async canLogInWithBiometrics() {
    return await this.$biometrics.hasBioSetup();
  }

  async logInWithBiometrics() {
    const canLogInWithBiometrics = await this.canLogInWithBiometrics();
    if (!canLogInWithBiometrics) return;
    this.$amplitude.track('AUTH_LOGIN_BIOMETRICS');
    const credentials = await this.$biometrics.verifyBio();
    if (credentials) {
      try {
        await this.logIn(credentials.username, credentials.password);
      } catch (error) {
        await this.$biometrics.removeBio();
        throw error;
      }
    } else {
      throw new Error("Error logging in with biometrics");
    }
  }

  async logIn(username: string, password: string) {
    const user = await User.logIn(username, password);
    if (user) {
      this.$events.userLoggedIn.next(user);
      this.$amplitude.track('AUTH_LOGIN_SUCCESS');
      await this.promptAndEnableBiometrics(username, password);
    } else {
      this.$amplitude.track('AUTH_LOGIN_FAIL');
    }
  }

  async promptAndEnableBiometrics(username: string, password: string) {
    const hasBio = await this.$biometrics.hasBio();
    if (!hasBio) return;
    const hasBioSetup = await this.$biometrics.hasBioSetup();
    if (hasBioSetup) return;
    const { value } = await Dialog.confirm({
      title: 'Enable Biometrics',
      message: 'Would you like to enable biometrics for easier log in?',
      okButtonTitle: 'Enable',
      cancelButtonTitle: 'Cancel'
    });
    if (!value) return;
    try {
      await this.$biometrics.setupBio(username, password);
    } catch (error) {
      await this.$biometrics.removeBio();
      throw { code: "biometric_fail", message: "Error setting up biometrics", error };
    }
  }

  async logOut() {
    await User.logOut();
    this.$events.userLoggedOut.next();
    this.$amplitude.track('AUTH_LOGOUT');
  }

  async signUp(email: string, username: string, password: string, first_name: string, last_name: string) {
    const user = await new User({ email, username, password, first_name, last_name }).signUp();
    await User.logIn(username, password);
    if (user) {
      this.$events.userLoggedIn.next(user);
      this.$amplitude.track('AUTH_SIGNUP_SUCCESS');
      await this.promptAndEnableBiometrics(username, password);
    } else {
      this.$amplitude.track('AUTH_SIGNUP_FAIL');
    }
  }
}