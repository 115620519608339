import { inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalController } from '@ionic/angular/standalone';
import { LoginPage } from '../pages/login/login.page';
import { CartPage } from '../pages/cart/cart.page';
import { SetAddressPage } from '../pages/set-address/set-address.page';
import { TSessionAddress } from '@chemist2u/types-client/C2U/Interfaces';
import { AppUpdaterPage } from '../pages/app-updater/app-updater.page';
import { AmplitudeService } from 'src/app/services/amplitude.service';

@Injectable({
  providedIn: 'root'
})
export class UiService {
  private modalController = inject(ModalController);
  private $amplitude = inject(AmplitudeService);

  constructor() {
    this.setupSubscriptions();
  }

  public showLogin: Subject<void> = new Subject();
  public showCartCheckout: Subject<void> = new Subject();
  public setAddress: Subject<TSessionAddress | undefined> = new Subject();
  public setDeliveryNotes: Subject<TSessionAddress> = new Subject();
  public setDeliveryMethod: Subject<TSessionAddress> = new Subject();
  public addPrescriptionsFromCart: Subject<void> = new Subject();
  public addOTCItemsFromCart: Subject<void> = new Subject();
  public showAppUpdate: Subject<boolean> = new Subject();


  setupSubscriptions() {
    const noSwipeDismiss = async (data?: any, role?: string) => role !== 'gesture';

    this.showLogin.subscribe(async () => {
      const modal = await this.modalController.create({
        component: LoginPage,
        canDismiss: true,
        animated: true,
        showBackdrop: true,
        presentingElement: await this.modalController.getTop() ? await this.modalController.getTop() : document.querySelector('ion-router-outlet')!,
      });
      await modal.present();
      this.$amplitude.track('MODAL_SHOW_LOGIN');
    });

    this.showCartCheckout.subscribe(async () => {
      const modal = await this.modalController.create({
        component: CartPage,
        canDismiss: noSwipeDismiss,
        animated: true,
        // breakpoints: [0,.75,.8,.9,1],
        // initialBreakpoint: 1,
        // handleBehavior: "none",
        // handle: true,
        showBackdrop: true,
        id: "cart-modal",
        //presentingElement: await this.modalController.getTop() ? await this.modalController.getTop() : document.querySelector('ion-router-outlet')!,
      });

      await modal.present();
      this.$amplitude.track('MODAL_SHOW_CART');
    });

    this.setAddress.subscribe(async (address: TSessionAddress | undefined) => {
      const props = address ? {
        setCurrentStep: 0,
        setFinalAddress: address,
      } : undefined;
      const modal = await this.modalController.create({
        component: SetAddressPage,
        canDismiss: true,
        animated: true,
        componentProps: props,
        showBackdrop: true,
        presentingElement: await this.modalController.getTop() ? await this.modalController.getTop() : document.querySelector('ion-router-outlet')!,
      });
      await modal.present();
      this.$amplitude.track('MODAL_SHOW_SET_ADDRESS');
    });

    this.setDeliveryMethod.subscribe(async (address: TSessionAddress) => {
      const props = {
        setCurrentStep: 2,
        setFinalAddress: address
      }
      const modal = await this.modalController.create({
        component: SetAddressPage,
        canDismiss: true,
        animated: true,
        showBackdrop: true,
        componentProps: props,
        presentingElement: await this.modalController.getTop() ? await this.modalController.getTop() : document.querySelector('ion-router-outlet')!,
      });
      await modal.present();
      this.$amplitude.track('MODAL_SHOW_SET_ADDRESS_METHOD');
    });

    this.setDeliveryNotes.subscribe(async (address: TSessionAddress) => {
      const props = {
        setCurrentStep: 1,
        setFinalAddress: address
      }
      const modal = await this.modalController.create({
        component: SetAddressPage,
        canDismiss: true,
        animated: true, 
        showBackdrop: true,
        componentProps: props,
        presentingElement: await this.modalController.getTop() ? await this.modalController.getTop() : document.querySelector('ion-router-outlet')!,
      });
      await modal.present();
      this.$amplitude.track('MODAL_SHOW_SET_ADDRESS_NOTES');
    });

    this.showAppUpdate.subscribe(async (canDismiss) => {
      const modal = await this.modalController.create({
        id: "app-updater-modal",
        component: AppUpdaterPage,
        focusTrap: true,
        keyboardClose: true,
        showBackdrop: true,
        canDismiss: canDismiss,
        backdropDismiss: canDismiss,
        presentingElement: document.querySelector('ion-router-outlet')!,
      });
      await modal.present();
      this.$amplitude.track('MODAL_SHOW_APP_UPDATE');
    });
  }


}