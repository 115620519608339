import { ChangeDetectionStrategy, Component, computed, input, signal } from '@angular/core';
import { IonImg } from '@ionic/angular/standalone';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IonImg],
})
export class ImageComponent {
  public local = input<boolean>(false);
  public imageCover = input<boolean>(false);

  // local
  public isIcon = input<boolean>(false);
  public name = input<string>('');

  // not local
  public src = input<string | undefined>(undefined);
  public alt = input<string>('');

  private hasFailed = signal<boolean>(false);

  public imgSrc = computed(() => {
    if (this.hasFailed() || (!this.local() && this.src() == undefined)) {
      return `assets/images/img-placeholder.svg`;
    } else if (this.local()) {
      if (this.isIcon()) {
        return `assets/icons/${this.name()}.svg`;
      } else {
        return `assets/images/${this.name()}`;
      }
    } else {
      return this.src();
    }
  })

  onLoadFail() {
    this.hasFailed.set(true);
  }

}