import { ChangeDetectionStrategy, Component, Input, output, signal } from '@angular/core';
import { AddressRowComponent } from '../address-row/address-row.component';
import { PlaceAutocompleteResult } from '@googlemaps/google-maps-services-js';

@Component({
  selector: 'app-address-list',
  templateUrl: './address-list.component.html',
  styleUrls: ['./address-list.component.scss'],
  standalone: true,
  imports: [AddressRowComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressListComponent  {
  @Input({required: true}) set predictions(preds: PlaceAutocompleteResult[]) { this._predictions.set(preds)}
  public addressSelected = output<PlaceAutocompleteResult>();

  _predictions = signal<PlaceAutocompleteResult[]>([]);

  get predictions(): PlaceAutocompleteResult[] {
    const value = this._predictions();
    if (value === undefined) {
      throw new Error('Predictions have not been initialized');
    }
    return value;
  }

  selectAddress(prediction: PlaceAutocompleteResult) {
    this.addressSelected.emit(prediction);
  }
  
}