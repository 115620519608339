import { Injectable } from '@angular/core';
import { ConnectionStatus } from '@capacitor/network';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  public userLoggedIn: Subject<Parse.User> = new Subject();
  public userLoggedOut: Subject<void> = new Subject();

  public triggerAuthChangeStart: Subject<void> = new Subject();
  public triggerAuthChangeComplete: Subject<void> = new Subject();

  public networkStatusChange: Subject<ConnectionStatus> = new Subject();

}