import { ChangeDetectionStrategy, Component, Input, computed, signal } from '@angular/core';
import { IonIcon } from "@ionic/angular/standalone";
import { addIcons } from 'ionicons';
import { chevronBackOutline, chevronForwardOutline, close, trash, trashBinOutline, addOutline, chatbubblesOutline, copyOutline, mapOutline } from 'ionicons/icons';

export type TPermittedIcon = 
  "chevron-back-outline" |
  "chevron-forward-outline" |
  "trash" |
  "trash-bin-outline" |
  "close" |
  "copy-outline" |
  "map-outline" |
  "add-outline" |
  "chatbubbles-outline" |
  "refresh-circle-outline" |
  undefined;

@Component({
  selector: 'app-simple-button',
  templateUrl: './simple-button.component.html',
  styleUrls: ['./simple-button.component.scss'],
  standalone: true,
  imports: [IonIcon],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleButtonComponent {

  @Input() set label(lab: string | undefined) { this._label.set(lab) }
  @Input() set disabled(disabled: boolean) { this._disabled.set(disabled) }
  @Input() set icon(icon: TPermittedIcon) { this._icon.set(icon) }
  @Input() set color(color: "tint" | "main") { this._color.set(color) }
  @Input() set size(size: "small" | "medium" | "large") { this._size.set(size) }
  @Input() set bold(bold: boolean) { this._bold.set(bold) }
  @Input() set iconLocation (iconLocation: 'left' | 'right') { this._iconLocation.set(iconLocation) }
  @Input() set border(border: boolean) { this._border.set(border) }

  private _label = signal<string | undefined>(undefined);
  private _disabled = signal(false);
  private _icon = signal<TPermittedIcon>(undefined);
  private _color = signal<"tint" | "main">("main");
  private _size = signal<"small" | "medium" | "large">("medium");
  private _bold = signal(false);
  private _iconLocation = signal<"left" | "right">("left");
  private _border = signal(false);

  constructor() {
    addIcons({ chevronBackOutline, chevronForwardOutline, trash, trashBinOutline, close, addOutline, chatbubblesOutline, copyOutline, mapOutline });
  }

  get label() { return this._label() }
  get disabled() { return this._disabled() }
  get icon() { return this._icon() }
  get color() { return this._color() }
  get size() { return this._size() }
  get bold() { return this._bold() }
  get iconLocation() { return this._iconLocation() }
  get border() { return this._border() }

  isDisabled = computed(() => this.disabled);

  isSmall = computed(() => this.size === 'small');
  isMedium = computed(() => this.size === 'medium');
  isLarge = computed(() => this.size === 'large');
  isMain = computed(() => this.color === 'main');
  isTint = computed(() => this.color === 'tint');
  isBold = computed(() => this.bold);

  iconLeft = computed(() => this.iconLocation === 'left');
  iconRight = computed(() => this.iconLocation === 'right');


}
