<div class="row" [class.unselectable]="!canSelect()" [class.nopadding]="noPadding()" (click)="selectedRow()" [class.last]="last()">
  <div class="image">
    <img src="./assets/icons/c2u-promotion-icon.svg" alt="">
  </div>
  <div class="content">
    <p class="code">{{ code() }}</p>
    <p class="description">{{ description() }}</p>
    <p class="expiry">{{ past() ? "Expired" : "Expires" }}: {{ expiryDate() | date:'d/MM/yyyy' }}</p>
  </div>
  @if (canSelect()) {
    <div class="chevron">
      <ion-icon color="grey" name="chevron-forward-outline"></ion-icon>
    </div>
  }
  @if(canClear()) {
    <div class="chevron">
      <ion-icon color="grey" name="close-outline" (click)="clear()"></ion-icon>
    </div>
  }
</div>