<div class="content">
  @for(total of totalsList()!; track $index) {
    @if(totalsList() && totalsList()!.length > 1) {
      <app-title title="Order {{ $index + 1 }}" />
    }
    <div class="totals">
      @if(total.ltOTCItems) {
        <app-cart-totals-row [lineItem]="total.ltOTCItems" />
      }
      
      @if(total.ltSavings) {
        <app-cart-totals-row [lineItem]="total.ltSavings" />
      }

      @if(total.ltPrescriptions) {
        <app-cart-totals-row [lineItem]="total.ltPrescriptions" />
      }

      @if(total.ltCollectionFee) {
        <app-cart-totals-row [lineItem]="total.ltCollectionFee" />
      }

      @if(total.ltShippingFee) {
        <app-cart-totals-row [lineItem]="total.ltShippingFee" [forceValueDisplay]="true" />
      }

      @if(total.ltRegionalFee) {
        <app-cart-totals-row [lineItem]="total.ltRegionalFee" />
      }

      @if(total.ltProgramFee) {
        <app-cart-totals-row [lineItem]="total.ltProgramFee" />
      } 

      @if(total.ltPromotionDiscount) {
        <app-cart-totals-row [lineItem]="total.ltPromotionDiscount" />
      }

      @if(total.ltConcessionDiscount) {
        <app-cart-totals-row [lineItem]="total.ltConcessionDiscount" />
      }

      @if(total.ltCollectionFeeDiscount) {
        <app-cart-totals-row [lineItem]="total.ltCollectionFeeDiscount" />
      }

      @if(total.ltSubTotal) {
        <app-cart-totals-row [lineItem]="total.ltSubTotal" />
      }

      @if(total.ltTotal) {
        <app-cart-totals-row [title]="true" [lineItem]="total.ltTotal" />
      }
    
      <!-- @if(total.discount && total.discount.value > 0) {
        <app-cart-totals-row [isDiscount]="true" label="Discount" [value]="total.discount" />
      }

      @if(total.regionalFee &&  total.regionalFee.value > 0) {
        <app-cart-totals-row label="Regional fee" [value]="total.regionalFee" />
      }

      <app-cart-totals-row [label]="deliveryString()" [value]="total.delivery" />
      <app-cart-totals-row [title]="true" label="Total" [value]="total.total" /> -->
      @if(hasUnpricedItems() && !hasPricedButPendingItems()) {
      <app-info-bubble
        label="Your cart has an unpriced item. We'll source pricing from our pharmacy partner once you submit your order. You'll receive a text with the updated price and payment instructions." />
      }

      @if(hasPricedButPendingItems()) {
      <app-info-bubble
        label="Your cart has items are priced but they need to be verified by our pharmacy partner before totals can be calculated. You'll receive a text with the updated price and payment instructions." />
      }

      @if($index === 1) {
      <app-info-bubble
        label="Your order contains weight loss medication. A separate order will be placed for weight loss products. The current totals only reflect the items which can be fulfilled from your local pharmacy." />
      }
    </div>
  }
  <app-encrypted-label />
</div>