import { Component, computed, inject, OnInit, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonContent, IonHeader, IonTitle, IonToolbar, ModalController, IonFooter, LoadingController } from '@ionic/angular/standalone';
import { HeaderComponent } from 'src/app/components/navigation/header/header.component';
import { SimpleButtonComponent } from 'src/app/components/primitives/simple-button/simple-button.component';
import { TitleComponent } from 'src/app/components/primitives/title/title.component';
import { FooterComponent } from 'src/app/components/navigation/footer/footer.component';
import { ButtonComponent } from 'src/app/components/primitives/button/button.component';
import { RadioComponent } from 'src/app/components/primitives/radio/radio.component';
import { TextareaComponent } from 'src/app/components/primitives/textarea/textarea.component';
import { StateService } from 'src/app/services/state.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { InfoBubbleComponent } from 'src/app/components/elements/info-bubble/info-bubble.component';
import { PushService } from 'src/app/services/push.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';

@Component({
  selector: 'app-atl',
  templateUrl: './atl.page.html',
  styleUrls: ['./atl.page.scss'],
  standalone: true,
  imports: [IonFooter, IonContent, IonHeader, IonTitle, IonToolbar, IonFooter, CommonModule, FormsModule, HeaderComponent, SimpleButtonComponent, TitleComponent, FooterComponent, ButtonComponent, RadioComponent, TextareaComponent, InfoBubbleComponent]
})
export class AtlPage implements OnInit {
  private $state = inject(StateService);
  private $push = inject(PushService);
  private loadingController = inject(LoadingController);
  private modalController = inject(ModalController);
  private $amplitude = inject(AmplitudeService);
  public atlSelected = signal<boolean>(false);
  public atlInstructions = signal<string | undefined>(undefined);
  // public atlSet = output<{ atl: boolean, instructions: string | undefined }>();
  public session = toSignal(this.$state.bSession);

  constructor() {
    if (this.session()?.address?.atl !== undefined) {
      this.atlSelected.set(this.session()?.address?.atl || false);
      this.atlInstructions.set(this.session()?.address?.deliveryNote);
    }
  }

  public canContinue = computed(() => {
    if (!this.atlSelected()) return true;
    if (this.atlSelected() && this.atlInstructions() && this.atlInstructions()?.trim().length) return true;
    return false;
  });

  ngOnInit() {
    this.$amplitude.track('MODAL_SHOW', { modal: 'atl' });
  }

  dismiss(data?: { atl: boolean, instructions: string | undefined }) {
    this.modalController.dismiss(data);
    this.$amplitude.track('MODAL_DISMISS', { modal: 'atl' });
  }

  toggle() {
    this.atlSelected.set(!this.atlSelected());
    this.$amplitude.track('ATL_TOGGLED');
  }

  async saveATL() {
    // this.atlSet.emit({
    //   atl: this.atlSelected(),
    //   instructions: this.atlInstructions()
    // });

    this.dismiss({
      atl: this.atlSelected(),
      instructions: this.atlInstructions()
    });
    // const loader = await this.loadingController.create({
    //   message: 'Saving ATL...',
    // });

    // try {
    //   await loader.present();
    //   await this.$push.session(this.session()!, {
    //     ...this.session()!.attributes,
    //     address: {
    //       ...this.session()!.address!,
    //       atl: this.atlSelected(),
    //       deliveryNote: this.atlInstructions()!.trim()
    //     }
    //   });
    //   await loader.dismiss();
    //   this.dismiss({
    //     atl: this.atlSelected(),
    //     instructions: this.atlInstructions()
    //   });
    //   this.$amplitude.track('ATL_SAVE_SUCCESS');
    // } catch (error) {
    //   await loader.dismiss();
    //   console.error("Error saving ATL", error);
    //   this.$amplitude.track('ATL_SAVE_FAIL');
    // } finally {
    //   await loader.dismiss();
    // }
  }
}