@if(!isClickAndCollectUnavailable()) {
  <div class="method" (click)="selectMethod()" [class.disabled]="isDisabled()" [class.selected]="methodSelected() && isSelectable() && !isDisabled()">
    <div class="head">
      <div class="name">
        @if(isSelectable()) {
          <app-radio [canToggle]="false" [disabled]="isDisabled()" [checked]="methodSelected()"></app-radio>
        } @else {
          @if(isClickAndCollect()) {
            <ion-icon name="storefront"></ion-icon>
          } @else {
            <app-image name="truck" [isIcon]="true" [alt]="scheduleName()" [local]="true" />
          }
          
        }
        <div class="label">{{deliveyLabelName()}}</div>
      </div>
      
      @if(!isAvailable()) {
        <div class="unavailable">Unavailable at this location</div>
      } @else {
        @if(distanceForClickAndCollect()) {
          <div class="distance">{{distanceForClickAndCollect() | number:'1.1-1'}} km</div>
        }
        
      }
    </div>
    <div class="details">
      <div class="name">{{scheduleName()}}</div>
      @if(free()) {
        <div class="price free">Free</div>
      } @else {
        <div class="price">{{price() | currency}}</div>
      }
    </div>
    <div class="description">
      {{description()}}
    </div>

    @if(pAddress()) {
      <div class="address">
        <ion-icon name="location-outline"></ion-icon>
        <div class="text">{{pAddress()}}</div>
      </div>
    }
    @if(hasControls() && methodSelected()) {
      <div class="controls">
        @if(canReschedule()) {
          <app-simple-button size="small" (click)="reschedule($event)" label="Reschedule" [border]="true" />
        }
        @if(canSetATL()) {
          <app-simple-button size="small" (click)="atl($event)" [label]="atlBtnLabel()" [border]="true" />
        }
      </div>
    }
   
   
  </div>
} @else {
  <div class="method disabled">
    <div class="head">
      <app-image name="truck" [isIcon]="true" alt="Click & Collect" [local]="true" />
      <div class="unavailable">Unavailable at this location</div>
    </div>
    <div class="details">
      <div class="name">Click & Collect</div>
      <div class="price free">Free</div>
    </div>
    <div class="description"> Available within 2 hours of payment</div>
  </div>
}
