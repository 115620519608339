<div class="content" [class.padding]="padding()" [class.border]="border()">
  <app-medical-profile-user-bubble [name]="name()" />
  <div class="details">
    <div class="name">{{name()}}</div>
    <div class="attributes">
      @if(concessionPresent()) {
        <app-medical-profile-info-row label="Concession discounts" />
      }
      @if(safetyNetPresent()) {
        <app-medical-profile-info-row label="SafetyNet discounts" />
      }
      
    </div>
  </div>
</div>