import { Component, input } from '@angular/core';
import { IonIcon } from "@ionic/angular/standalone";

@Component({
  selector: 'app-medical-profile-info-row',
  templateUrl: './medical-profile-info-row.component.html',
  styleUrls: ['./medical-profile-info-row.component.scss'],
  standalone: true,
  imports: [IonIcon]
})
export class MedicalProfileInfoRowComponent {
  public label = input.required<string>();
}