import { Component, inject, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonContent, IonHeader, IonTitle, IonList, IonLabel, IonRadio, IonItem, IonRadioGroup, IonFooter, IonToolbar, IonRefresher, ModalController, ToastController } from '@ionic/angular/standalone';
import { HeaderComponent } from 'src/app/components/navigation/header/header.component';
import { SimpleButtonComponent } from 'src/app/components/primitives/simple-button/simple-button.component';
import { TitleComponent } from 'src/app/components/primitives/title/title.component';
import { FooterComponent } from 'src/app/components/navigation/footer/footer.component';
import { ButtonComponent } from 'src/app/components/primitives/button/button.component';
import { CloudFunctionsService } from 'src/app/services/cloud.functions.service';
import { StateService } from 'src/app/services/state.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { TAllocatedShift, TWeekDays } from '@chemist2u/types-client/C2U/Interfaces';
import { RescheduleDayComponent } from 'src/app/components/elements/reschedule-day/reschedule-day.component';
import { RescheduleTimeComponent } from 'src/app/components/elements/reschedule-time/reschedule-time.component';
import { AmplitudeService } from 'src/app/services/amplitude.service'; // Added import

type TShiftWithExtra = TAllocatedShift & {
  shiftString?: string;
};

@Component({
  selector: 'app-reschedule',
  templateUrl: './reschedule.page.html',
  styleUrls: ['./reschedule.page.scss'],
  standalone: true,
  imports: [IonRefresher, IonContent, IonLabel, IonRadio, IonItem, IonList, IonRadioGroup, IonHeader, IonFooter, IonTitle, IonToolbar, CommonModule, FormsModule, HeaderComponent, SimpleButtonComponent, TitleComponent, FooterComponent, ButtonComponent, RescheduleDayComponent, RescheduleTimeComponent]
})
export class ReschedulePage implements OnInit {
  private $cloud = inject(CloudFunctionsService);
  private $state = inject(StateService);
  private modalController = inject(ModalController);
  private toastController = inject(ToastController);
  private $amplitude = inject(AmplitudeService);
  public shifts = signal<{ simpleShifts: TAllocatedShift[] }>({ simpleShifts: [] });
  private session = toSignal(this.$state.bSession);
  public formattedShifts: {
    day: TWeekDays,
    date: string,
    shifts: TAllocatedShift[]
  }[] = [];
  public showshifts:TShiftWithExtra[] = [];
  public selectedDate: {
    day: TWeekDays,
    date: string,
    shifts: TAllocatedShift[]
  } | undefined;
  public selectedShiftId: string | undefined = undefined;

  constructor() { }

  ngOnInit() {
    this.$amplitude.track('MODAL_SHOW_RESCHEDULE');
    if (this.session()?.pharmacy?.id) {
      this.$cloud.getShiftsForPharmacy(this.session()?.pharmacy?.id!).then((shifts) => {
        this.shifts.set(shifts);
        this.formatShifts();
      });
    }
  }

  dismiss(data?: any) {
    this.modalController.dismiss(data);
    this.$amplitude.track('MODAL_DISMISS_RESCHEDULE');
  }

  formatShifts() {
    for (let index = 0; index < this.shifts().simpleShifts.length; index++) {
      const shift = this.shifts().simpleShifts[index];
      const shiftIndex = this.formattedShifts.findIndex(s => s.day == shift.day)
      //@ts-ignore
      shift['shiftString'] = this.getDeliveryString(shift);
      if (this.dateCompare(shift.cutoff, new Date())) continue;
      if (shiftIndex !== -1) {
        this.formattedShifts[shiftIndex].shifts.push(shift)
      }
      else this.formattedShifts.push({
        day: shift.day,
        date: this.formatdate(shift.date),
        shifts: [shift]
      })
    }
    this.formattedShifts = this.formattedShifts.slice(0, 3);
    this.selectedDate = this.formattedShifts[0];
    this.showshifts = this.formattedShifts[0].shifts;
  }

  onSelection(id: string) {
    this.selectedShiftId = id;
  }

  formatdate(shiftDate: Date): string {
    const dateObj = shiftDate;
    const day = dateObj.getDate();
    const month = dateObj.toLocaleString("default", { month: "long" });

    const nthNumber = (number: number) => {
      if (number > 3 && number < 21) return "th";
      switch (number % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    const date = `${day}${nthNumber(day)} ${month} `;
    return date;
  }

  getDeliveryString(shift: TAllocatedShift): any {
    const startString = this.formatTime(shift.pickup.getHours(), shift.pickup.getMinutes());
    const endString = this.formatTime(shift.dropoff.getHours(), shift.dropoff.getMinutes());
    return `${startString} to ${endString}`
  }

  formatTime(hours: number, minutes: number): string {
    const hour = hours % 24;
    return (hour % 12 || 12) + ":" + minutes.toString().padStart(2, '0') + (hour < 12 ? " AM" : " PM");
  }

  dateCompare(date1: Date, date2: Date): boolean {
    return new Date(date2) > new Date(date1);
  }

  changeDate(index: number) {
    this.selectedDate = this.formattedShifts[index];
    this.showshifts = this.formattedShifts[index].shifts;
    this.selectedShiftId = "";
  }

  async change() {
    const shift = this.showshifts.find(shift => shift.id === this.selectedShiftId);
    // @ts-ignore
    delete shift['shiftString'];
    const toast = await this.toastController.create({
      message: 'Delivery instructions updated',
      duration: 3000,
      position: 'top',
      cssClass: 'success-toast',
      swipeGesture: "vertical"
    });
    toast.present();
    this.dismiss(shift);
  }

}