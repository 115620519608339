<ion-header>
  <app-header background="faded">
    <app-simple-button (click)="close()" color="main" size="large" slot="start" icon="close" />
    <app-title slot="main" title="Reset Password" />
  </app-header>
</ion-header>

<ion-content>
  <div class="wrapper">
    <app-section-description title="Reset Password" description="Enter the email address associated with your account, and we'll email you a link to reset your password " />
    <app-login-wrapper>
      <app-input type="email" label="Email Address" [value]="emailAddress()" (valueChange)="onEmailChange($event)" [showClearButtonOption]="true" />
    </app-login-wrapper>
  </div>
</ion-content>

<ion-footer>
  <app-footer>
    <app-button [disabled]="!isEmailValid()" (click)="resetPassword()" size="large" label="Reset Password" />
  </app-footer>
</ion-footer>
