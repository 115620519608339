import { Component, computed, effect, inject, Input, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonFooter, IonButton, IonButtons } from '@ionic/angular/standalone';
import { HeaderComponent } from 'src/app/components/navigation/header/header.component';
import { FooterComponent } from 'src/app/components/navigation/footer/footer.component';
import { ModalController, LoadingController, ToastOptions, AlertController } from '@ionic/angular/standalone';
import { AddressListComponent } from 'src/app/components/elements/address-list/address-list.component';
import { TitleComponent } from 'src/app/components/primitives/title/title.component';
import { SimpleButtonComponent } from 'src/app/components/primitives/simple-button/simple-button.component';
import { AddressDetailsComponent } from 'src/app/components/elements/address-details/address-details.component';
import { ButtonComponent } from 'src/app/components/primitives/button/button.component';
import { DeliveryMethodComponent } from 'src/app/components/elements/delivery-method/delivery-method.component';
import { InputComponent } from 'src/app/components/primitives/input/input.component';
import { CheckboxComponent } from 'src/app/components/primitives/checkbox/checkbox.component';
import { CounterComponent } from 'src/app/components/primitives/counter/counter.component';
import { PriceComponent } from 'src/app/components/elements/price/price.component';
import { ErrorService } from 'src/app/services/error.service';
import { TCartItem } from '@chemist2u/types-client/C2U/Interfaces';
import { ItemFullComponent } from 'src/app/components/view/item-full/item-full.component';
import { FetchService } from 'src/app/services/fetch.service';
import { CartService } from 'src/app/services/cart.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';

@Component({
  selector: 'app-item-cart-edit',
  templateUrl: './item-cart-edit.page.html',
  styleUrls: ['./item-cart-edit.page.scss'],
  standalone: true,
  imports: [IonButtons, IonButton, IonFooter, IonContent, IonHeader, IonTitle, IonToolbar, CommonModule, FormsModule, HeaderComponent, FooterComponent, InputComponent, AddressListComponent, TitleComponent, SimpleButtonComponent, AddressDetailsComponent, ButtonComponent, DeliveryMethodComponent, CheckboxComponent, CounterComponent, PriceComponent, ItemFullComponent]
})
export class ItemCartEditPage implements OnInit {

  private stdError: ToastOptions = {
    message: "Item not found",
    header: "Error",
    position: "top",
    duration: 2000,
  }

  private $amplitude = inject(AmplitudeService);
  private $cart = inject(CartService);
  private $fetch = inject(FetchService);
  private $error = inject(ErrorService);
  private modalController = inject(ModalController);
  private loadingController = inject(LoadingController);
  private alertController = inject(AlertController);

  public swapEnabled = signal<boolean>(false);
  public count = signal<number>(1);
  public getTotalPrice: number = 0;

  private loader: HTMLIonLoadingElement | undefined;

  @Input({ required: true }) product!: TCartItem;

  constructor() {
    effect(() => {
      this.getTotalPrice = this.price() * this.count();
    });
  }

  async ngOnInit() {
    this.count.set(this.product.qty);
    this.loader = await this.loadingController.create();
    const item = await this.$fetch.itemById(this.product.objectId);
    if (this.product) {
      this.swapEnabled.set(this.product.swapEnabled || false);
    }
    if (item && item.description) {
      this.description.set(item.description);
    }
    this.$amplitude.track('CART_ITEM_EDIT_OPENED');
  }

  public name = computed(() => {
    return this.product.name;
  })

  public imageSrc = computed(() => {
    if (this.product.featuredImage) {
      return `url(${this.product.featuredImage._url})`;
    }
    if (this.product.featuredImageThumb) {
      return `url(${this.product.featuredImageThumb._url})`;
    }
    return null;
  });

  public price = computed(() => {
    return this.product.price;
  });

  public description = signal<string>("");

  public buttonLabel = computed(() => {
    return `Update Qty to ${this.count()} `;
  });

  onCountInc() {
    this.$amplitude.track('CART_ITEM_EDIT_QTY_INC', { id: this.product.objectId, name: this.product.name, qty: this.count() });
  }

  onCountDec() {
    this.$amplitude.track('CART_ITEM_EDIT_QTY_DEC', { id: this.product.objectId, name: this.product.name, qty: this.count() });
  }

  dismiss(data?: any) {
    this.modalController.dismiss(data);
    this.$amplitude.track('CART_ITEM_EDIT_CLOSED');
  }

  async removeItem() {
    const alert = await this.alertController.create({
      header: 'Remove item',
      message: 'Are you sure',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
        }, {
          text: 'Yes',
          handler: async () => {
            await this.removeItemFromCart();
          }
        }
      ]
    });
    alert.present();
  }

  async removeItemFromCart() {
    try {
      await this.loader!.present();
      await this.$cart.removeOTCItem(this.product.objectId);
      this.$amplitude.track('CART_ITEM_REMOVE_SUCCESS', { id: this.product.objectId, name: this.product.name });
    } catch (e: unknown) {
      this.$error.showToast({ ...this.stdError, message: "Could not remove item from cart." });
      this.$amplitude.track('CART_ITEM_REMOVE_FAIL', { id: this.product.objectId, name: this.product.name });
    } finally {
      this.loader!.dismiss();
      this.dismiss();
    }
  }

  async updateCart() {
    try {
      await this.loader!.present();
      await this.$cart.modifyOTCItemQty(this.product.objectId, this.count());
      this.$amplitude.track('CART_ITEM_EDIT_SUCCESS', { id: this.product.objectId, name: this.product.name, qty: this.count() });
    } catch (e: unknown) {
      this.$error.showToast({ ...this.stdError, message: "Could not update item in cart." });
      this.$amplitude.track('CART_ITEM_EDIT_FAIL');
    } finally {
      this.loader!.dismiss();
      this.dismiss();
    }
  }
}