import { Component, output } from '@angular/core';
import { SimpleButtonComponent } from 'src/app/components/primitives/simple-button/simple-button.component';

@Component({
  selector: 'app-otc-items-empty',
  templateUrl: './otc-items-empty.component.html',
  styleUrls: ['./otc-items-empty.component.scss'],
  standalone: true,
  imports: [SimpleButtonComponent]
})
export class OtcItemsEmptyComponent {

}