import { ChangeDetectionStrategy, Component, Input, computed, input, signal } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  @Input() set extra(value: boolean) {
    this._extra.set(value);
  }


  @Input() set background(value: "light" | "faded" | undefined) {
    this._background.set(value);
  }

  public border = input<boolean>(false);

  private _background = signal<"light" | "faded" | undefined>("light");

  private _extra = signal(false);

  get extra() {
    return this._extra();
  }

  get background() {
    return this._background();
  }

  isExtra = computed(() => {
    return this.extra;
  });

  isLight = computed(() => {
    return this.background === "light" || this.background === undefined;
  });
  
}