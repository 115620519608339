import { Component, computed, inject, input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-checkout-tab',
  templateUrl: './checkout-tab.component.html',
  styleUrls: ['./checkout-tab.component.scss'],
  standalone: true,
})
export class CheckoutTabComponent {
  private sanitizer = inject(DomSanitizer);

  public active = input<boolean>(false);
  public label = input<string>("Label");
  public icon = input.required<string>();

  public safeIcon = computed(() => {
    let iconString = this.icon();
    if(this.active()) {
      //replace fille
      iconString = iconString.replace(/fill="DYNAMIC"/g, "fill='#D92050'");
    } else {
      iconString = iconString.replace(/fill="DYNAMIC"/g, "fill='#3D5D6E'");
    }
    return this.sanitizer.bypassSecurityTrustHtml(iconString);
  });

}