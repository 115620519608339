import { inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActionPerformed, PushNotificationSchema, PushNotifications, RegistrationError, Token } from '@capacitor/push-notifications';
import { NavController } from '@ionic/angular/standalone';
import { Capacitor } from '@capacitor/core';
import packageJson from '../../../package.json';
import { Device } from '@capacitor/device';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { App } from '@capacitor/app';
import { IInstallation } from '@chemist2u/types-client/C2U/Interfaces';
import { StateService } from './state.service';
import { NativePermissionService } from './native-permission.service';
import { Parse } from '@chemist2u/types-client/C2U/local-parse';
import { ErrorService } from './error.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {
    private $state = inject(StateService);
    private $error = inject(ErrorService);
    private $nativePermission = inject(NativePermissionService);
    private navController = inject(NavController);
    private $amplitude = inject(AmplitudeService);

    // -- Public Functions

    public async init() {
        if (!Capacitor.isNativePlatform()) return;

        const permission = await this.$nativePermission.requestPermissionsFor("PUSH_NOTIFICATIONS");
        if (permission != "granted") return;
        
        await this.addListeners();
        await PushNotifications.register();
    }

    public async destroy() {
        this.$amplitude.track('PUSH_NOTIFICATION_UNREGISTERED');
        await PushNotifications.unregister();
        await this.removeListeners();
    }

    // -- Listeners

    private async addListeners() {
        // On success, we should be able to receive notifications
        await PushNotifications.addListener('registration', this.pnRegistration.bind(this));
        // Some issue with our setup and push will not work
        await PushNotifications.addListener('registrationError', this.pnRegistrationError.bind(this));
        // Show us the notification payload if the app is open on our device
        await PushNotifications.addListener('pushNotificationReceived', this.pnReceived.bind(this));
        // Method called when tapping on a notification
        await PushNotifications.addListener('pushNotificationActionPerformed', this.pnActionPerformed.bind(this));
    }

    private async removeListeners() {
        await PushNotifications.removeAllListeners();
    }

    private async pnRegistration(token: Token) {
        console.warn("[PushNotificationsService]", "registration", token.value);
        await this.saveInstallationSession(token.value);
        this.$amplitude.track('PUSH_NOTIFICATION_REGISTRATION_SUCCESS');
    }

    private async pnRegistrationError(error: RegistrationError) {
        console.warn("[PushNotificationsService]", "registrationError", error);
        this.$amplitude.track('PUSH_NOTIFICATION_REGISTRATION_FAIL');
    }

    private async pnReceived(notification: PushNotificationSchema) {
        console.warn("[PushNotificationsService]", "pushNotificationReceived", notification);
        if (!notification || !notification.data || !notification.data.type) return;
        const type = notification.data.type;
        const id = notification.data.id;
        if (!type || !id || type != 'approve') return;
        this.$amplitude.track('PUSH_NOTIFICATION_RECEIVED');
    }

    private async pnActionPerformed(notification: ActionPerformed) {
        console.warn("[PushNotificationsService]", "pushNotificationActionPerformed", notification);
        const type = notification.notification.data.type;
        const id = notification.notification.data.id;
        if (!type || !id || type != 'approve') return;
        this.goToOrder(id);
        this.$amplitude.track('PUSH_NOTIFICATION_ACTION_PERFORMED');
    }

    // -- Handlers

    private async goToOrder(objectId: string) {
        try {
            this.navController.navigateForward(`/order/${objectId}`);
        } catch (error) {
            console.error("Error navigating to order");
        }
    }

    private async getAppVersion() {
        const current = await CapacitorUpdater.current();
        return current.bundle.version == "builtin" ? packageJson.version : current.bundle.version;
    }

    private getInternalLocalInstallationId() {
        return localStorage.getItem(`Parse/${environment.appId}/currentInstallationId`) || undefined;
    }
    
    private async saveInstallationSession(deviceToken: string) {
        console.warn("[PushNotificationsService]", "saveInstallationSession", "Start", deviceToken);

        const user = this.$state.bUser.getValue();
        const appIdentifier = (await App.getInfo()).id;
        const deviceType = Capacitor.getPlatform() as 'ios' | 'android';
        const installationId = this.getInternalLocalInstallationId();
        const appVersion = await this.getAppVersion();
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const uuid = (await Device.getId()).identifier || '';

        const installationObj: IInstallation = {
            localeIdentifier: 'en-AU',
            badge: 0,
            appName: environment.appName,
            pushType: 'gcm',
            isPushEnabled: true,
            installationId,
            deviceToken,
            deviceType,
            timeZone,
            appVersion,
            appIdentifier,
            user,
            uuid,
        };
        console.warn("[PushNotificationsService]", "saveInstallationSession", "Installation", installationObj);
        try {
            const installation = await new Parse.Installation(installationObj).save();
            console.warn("[PushNotificationsService]", "saveInstallationSession", "Success", installation);
        } catch (error: any) {
            console.warn("[PushNotificationsService]", "saveInstallationSession", "Error", error);
            this.$error.handleParseError(error);
            this.$amplitude.track('PUSH_NOTIFICATION_REGISTRATION_FAIL');
            throw error;
        }
    }
}