import { Component, input, output } from '@angular/core';
import { MedicalProfileHeaderComponent } from '../medical-profile-header/medical-profile-header.component';
import { PrescriptionRowComponent } from '../prescription-row/prescription-row.component';
import { ICustomerMedicalProfile, TOrderPrescriptionScriptItem } from '@chemist2u/types-client/C2U/Interfaces';

@Component({
  selector: 'app-prescription-card',
  templateUrl: './prescription-card.component.html',
  styleUrls: ['./prescription-card.component.scss'],
  standalone: true,
  imports: [MedicalProfileHeaderComponent, PrescriptionRowComponent]
})
export class PrescriptionCardComponent {

  public medicalProfile = input.required<Parse.Object.ToJSON<ICustomerMedicalProfile> & Parse.JSONBaseAttributes>();
  public items = input.required<TOrderPrescriptionScriptItem[]>();
  public canRemoveItems = input<boolean>(true);
  public removeItemEvent = output<TOrderPrescriptionScriptItem>();

  removeItem(index: number) {
    this.removeItemEvent.emit(this.items()[index]);
  }

}