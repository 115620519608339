<ion-header>
  <app-header background="faded">
    <app-simple-button (click)="dismiss()" size="large" icon="close" slot="start" />
  </app-header>
</ion-header>

<ion-content>
  <div class="content">
    <div class="title">Delivery details</div>
    <div class="option" (click)="toggle()">
      <app-radio  [checked]="!atlSelected()" />
      <p>Someone will be at home</p>
    </div>
    <div class="option" (click)="toggle()">
      <app-radio [checked]="atlSelected()" />
      <p>Authorise to leave</p>
    </div>
    @if(atlSelected()) {
      <div class="details">
        <app-textarea label="Where should it be left?" [required]="true" [(value)]="atlInstructions" />
      </div>

      <app-info-bubble type="warning" color="yellow" label="Only provide authority to leave in a private and secure location. We take no responsibility for lost items." />
    }

  </div>

</ion-content>

<ion-footer>
  <app-footer>
    <app-button [disabled]="!canContinue()" (clicked)="saveATL()" size="large" label="Save" />
  </app-footer>
</ion-footer>