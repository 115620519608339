<ion-header>
  <app-header background="faded">
    <app-simple-button (click)="dismiss()" size="large" icon="close" slot="start" />
    <app-title slot="main" size="small" title="Add Card Details" />
  </app-header>
</ion-header>

<ion-content>
  <div class="payment">
    @if (isLoading()) {
      <div class="loading">
        <p class="label">Loading payment form...</p>
      </div>
    }
    <form id="payment-form" [ngClass]="{ visible: !isLoading() }">
      <div id="addCardPage-cardContainer"></div>
    </form>
  </div>
</ion-content>

<ion-footer>
  <app-footer>
    <app-button [disabled]="isLoading()" (clicked)="addCard()" size="large" label="Add Card" />
  </app-footer>
</ion-footer>