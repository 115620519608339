import { Injectable } from '@angular/core';
import { Parse } from '@chemist2u/types-client/C2U/local-parse';
import { TAllocatedShift, TPharmacyOpeningHours, TWeekDays } from '@chemist2u/types-client/C2U/Interfaces';

@Injectable({
  providedIn: 'root'
})
export class TimeService {
  formatPharmacyHours(openingHours: TPharmacyOpeningHours | Parse.Object.ToJSON<TPharmacyOpeningHours>) {
    const today = new Date().getDay();
    // Convert today into a number between 0 and 6 where 0 is Monday and 6 is Sunday
    const todayNumber = today == 0 ? 6 : today - 1;
    const periods = openingHours.periods;
    const todayPeriod = periods.find(period => period.open.day == todayNumber);
    if (!todayPeriod) return "Closed today";
    // Convert open.time 0800 to 8:00 am and close.time 1700 to 5:00 pm
    const openTime = todayPeriod.open.time;
    const closeTime = todayPeriod.close.time;
    return `${this.formatTime(openTime)} - ${this.formatTime(closeTime)}`;
  }

  formatShiftLabel(shift: TAllocatedShift): string {
    const formatOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', hour12: true, minute: 'numeric' };
    const locale = 'en-AU'; // Adjust locale if necessary

    const pickupTime = shift.pickup.toLocaleTimeString(locale, formatOptions).toLowerCase();
    const dropoffTime = shift.dropoff.toLocaleTimeString(locale, formatOptions).toLowerCase();

    let dayLabel: TWeekDays | string = shift.day;
    const currentDate = new Date();
    const shiftDate = new Date(shift.date);

    if (shiftDate.toDateString() === currentDate.toDateString()) {
      dayLabel = 'Today';
    } else if (shiftDate.getDate() === currentDate.getDate() + 1 && shiftDate.getMonth() === currentDate.getMonth()) {
      dayLabel = 'Tomorrow';
    }
    return `${dayLabel}, ${pickupTime} - ${dropoffTime}`;
  }

  formatStandardDeliveryLabel(date: Date): string {
    let dayLabel: TWeekDays | string = this.formatDay(date);
    const currentDate = new Date();

    if (date.toDateString() === currentDate.toDateString()) {
      dayLabel = 'Today';
    } else if (date.getDate() === currentDate.getDate() + 1 && date.getMonth() === currentDate.getMonth()) {
      dayLabel = 'Tomorrow';
    }

    return `${dayLabel}, 1:00 PM - 5:00 PM`;
  }

  formatTime(time: string): string {
    // Convert the string to a number and extract hours and minutes
    const timeNumber = parseInt(time);
    const hours = Math.floor(timeNumber / 100);
    const minutes = timeNumber % 100;
  
    // Format the hours for 12-hour clock and determine AM/PM
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  
    // Convert minutes to a string and ensure it's two digits
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();
  
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  }
  
  formatDate(date: Date) {
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12;
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    const ordinalSuffix = (day: number) => {
      if (day > 3 && day < 21) return 'th'; // Covers 11th, 12th, 13th, etc.
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };
  
    return `${day}${ordinalSuffix(day)} ${month}, ${formattedHours}:${minutes}${ampm}`;
  }
  
  formatDay(date: Date) {
    const daysOfWeek: TWeekDays[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    let dayLabel: TWeekDays | string = daysOfWeek[date.getDay()];
    const currentDate = new Date();

    if (date.toDateString() === currentDate.toDateString()) {
      dayLabel = 'Today';
    } else if (date.getDate() === currentDate.getDate() + 1 && date.getMonth() === currentDate.getMonth()) {
      dayLabel = 'Tomorrow';
    }

    return dayLabel;
  }
}