import { inject, Injectable } from '@angular/core';
import { filter } from 'rxjs';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { NavigationEnd, Router } from '@angular/router';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root'
})
export class HubspotService {
  private router = inject(Router);
  private $amplitude = inject(AmplitudeService);
  private $state = inject(StateService);

  private isLoggedIn = this.$state.isLoggedIn;

  async wait(time: number) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  async show() {
    await this.wait(2000);

    window.hsConversationsSettings = {
      loadImmediately: false,
      disableAttachment: false,
    };

    window.HubSpotConversations.widget.remove();

    const currRoute = this.router.url;
    const goodPage = currRoute == '/tabs/account';

    if (goodPage && this.isLoggedIn()) {
      this.loadWidget();
    }

    this.listenRouteChanges();
  }

  private loadWidget() {
    window.HubSpotConversations.widget.load();
    
    window.HubSpotConversations.on('conversationStarted', (payload: any) => {
      const id = payload.conversation.conversationId;
      this.$amplitude.track('HUBSPOT_OPEN', { conversationId: id });
    });

    window.HubSpotConversations.on('conversationClosed', (payload: any) => {
      const id = payload.conversation.conversationId;
      this.$amplitude.track('HUBSPOT_CLOSE', { conversationId: id });
    });
  }

  private removeWidget() {
    window.HubSpotConversations.off('conversationStarted', (payload: any) => {
      console.log("conversationStarted off payload?!?", payload);
    });

    window.HubSpotConversations.off('conversationClosed', (payload: any) => {
      console.log("conversationClosed off payload?!?", payload);
    });

    window.HubSpotConversations.widget.remove();
  }

  private listenRouteChanges() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(event => {
        const currRoute = this.router.url;
        const goodPage = currRoute == '/tabs/account';
        if (!goodPage) {
          this.removeWidget();
        } else if (this.isLoggedIn()) {
          window.HubSpotConversations.widget.load();
        }
      });
  }
}