import { inject, Injectable } from '@angular/core';
import { StateService } from './state.service';
import { CloudFunctionsService } from './cloud.functions.service';
import { App } from '@capacitor/app';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class MiscService {
  private $state = inject(StateService);
  private $cloud = inject(CloudFunctionsService);
  private platform = inject(Platform);
  
  constructor() {
    this.$state.bInconvenienceMessage.subscribe(message => {
      console.log("[MiscService]", "bInconvenienceMessage", message);
    });
  }

  public init() {
    this.getInconvencienceMessage();
  }

  private async getInconvencienceMessage() {
    await this.checkForInconvenienceMessage();
    this.platform.ready().then(() => {
      App.addListener('appStateChange', async state => {
        if (state.isActive) {
          await this.checkForInconvenienceMessage();
        }
      });
    });
  }

  // TODO - need to move time check 2 hours function from banner component into here and only call banner update if it was dismissed and falls outside of the two hour interval
  // otherwise this will be retrieved every time app goes to foreground
  private async checkForInconvenienceMessage() {
    const inconvenienceMessage = await this.$cloud.getApplicationDeliveryInconvenienceMessage();
    this.$state.bInconvenienceMessage.next(inconvenienceMessage);
  }

}
