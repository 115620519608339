import { Component, computed, input } from '@angular/core';

@Component({
  selector: 'app-medical-profile-user-bubble',
  templateUrl: './medical-profile-user-bubble.component.html',
  styleUrls: ['./medical-profile-user-bubble.component.scss'],
  standalone: true,
})
export class MedicalProfileUserBubbleComponent {
  public name = input.required<string>();

  //Need to write a computed function which takes a name and returns the first letter of first name and last name and capitalises it, some names are one word others two others modre than two
  public initials = computed(() => {
    const names = this.name().split(' ');
    if (names.length === 1) {
      return names[0].charAt(0).toUpperCase();
    } else {
      return names[0].charAt(0).toUpperCase() + names[names.length - 1].charAt(0).toUpperCase();
    }
  });

}