<ion-header>
  <app-header>
    @if(pageState() === "page") {
      <app-simple-button (click)="back()" color="main" size="large" slot="start" icon="chevron-back-outline" />
    }
   
    <app-title slot="main" size="medium" title="Payment Methods" />
    @if(pageState() === "modal") {
      <app-simple-button (click)="back()" color="main" size="large" slot="end" icon="close" />
    }
   
  </app-header>
</ion-header>

<ion-content>
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)" >
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  @if (isLoading()) {
    <app-loading-view></app-loading-view>
  } @else {
    @if (payments()!.length == 0) {
      <div class="wrapper">
        <div class="content empty">
          <h3>Set up a payment method to proceed</h3>
          <p>No payment methods currently setup. </p>
          <app-button [disabled]="addButtonIsDisabled()" (click)="addPaymentMethod()" label="Add Payment Method"></app-button>
        </div>
      </div>
    }
    @if (payments()!.length > 0) {
      <div class="wrapper">
        <div class="content">
          <ion-list>
            @for (payment of payments(); track $index) {
              <ion-item-sliding>
                <ion-item lines="none">
                  <app-payment-row
                    [mode]="paymentRowMode()"
                    [card]="payment"
                    (click)="selectPayment(payment)"
                    (delete)="destroyCardData(payment)"
                  ></app-payment-row>
                </ion-item>
                <ion-item-options side="end">
                  <ion-item-option color="danger" (click)="destroyCardData(payment)">Remove</ion-item-option>
                </ion-item-options>
              </ion-item-sliding>
            }
          </ion-list>
          <div class="controls">
            <app-simple-button label="Add payment method" color="tint" icon="add-outline" size="large" (click)="addPaymentMethod()"></app-simple-button>
          </div>
        </div>
      </div>
    }
  }
</ion-content>
