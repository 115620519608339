import { ApplicationRef, createComponent, ComponentRef, signal, EnvironmentInjector, Injectable, Injector, input, NgZone } from '@angular/core';
import { TooltipComponent } from '../components/primitives/tooltip/tooltip.component';
import { fromEvent, merge, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Platform } from '@ionic/angular/standalone';

@Injectable({
  providedIn: 'root'
})
export class TooltipService {
  private tooltipComponentRef: ComponentRef<TooltipComponent> | null = null;
  private hideSubscriptions: Subscription[] = [];

  constructor(
    private injector: Injector,
    private applicationRef: ApplicationRef,
    private environmentInjector: EnvironmentInjector,
    private ngZone: NgZone,
    private platform: Platform
  ) { }

  async show(options: { label: string, x: number, y: number }): Promise<void> {
    this.hide(); // Hide any existing tooltip

    // Create a component reference
    this.tooltipComponentRef = createComponent(TooltipComponent, {
      environmentInjector: this.environmentInjector,
      elementInjector: this.injector
    });

    // Set the label using a signal
    this.tooltipComponentRef.instance.label = options.label;

    // Set the triangle's left position
    this.tooltipComponentRef!.instance.triangleLeft = options.x -10;

    // Attach component to the appRef so that it will be dirty checked.
    this.applicationRef.attachView(this.tooltipComponentRef.hostView);

    // Get DOM element from component
    const domElem = this.tooltipComponentRef.location.nativeElement;

    // Append DOM element to the body
    document.body.appendChild(domElem);

    // Wait for the next frame to ensure the element is rendered
    this.ngZone.runOutsideAngular(() => {
      requestAnimationFrame(() => {
        // In your show method inside requestAnimationFrame
        const tooltipElement = domElem.querySelector('.tooltip') as HTMLElement;
        const tooltipRect = tooltipElement.getBoundingClientRect();
        const realTooltipHeight = tooltipRect.height;
        const realTooltipWidth = tooltipRect.width;
        const triangleHeight = 9; // Adjust based on your design


        // Initial positioning
        let left = options.x - (realTooltipWidth / 2);
        

        let top = options.y - realTooltipHeight - triangleHeight - 10;

        // Adjust if the tooltip goes off-screen
        if (left < 10) {
          left = 10; // Minimal left offset
        } else if (left + realTooltipWidth > window.innerWidth - 10) {
          left = window.innerWidth - realTooltipWidth - 10; // Maximal left offset
        }

        // If not enough space above, display below the cursor
        if (top < 10) {
          top = options.y + triangleHeight + 10;
        }

        domElem.style.left = `${left}px`;
        domElem.style.top = `${top}px`;



        // Adjust if the tooltip goes off-screen
        this.adjustPosition(domElem);

        // Add event listeners to hide tooltip
        this.addHideListeners();
      });
    });
  }

  private adjustPosition(domElem: HTMLElement) {
    const tooltipRect = domElem.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Adjust horizontal position if off-screen
    if (tooltipRect.right > viewportWidth) {
      const overflowRight = tooltipRect.right - viewportWidth;
      domElem.style.left = `${parseInt(domElem.style.left) - overflowRight - 20}px`;
    }

    // Adjust vertical position if off-screen
    if (tooltipRect.top < 0) {
      domElem.style.top = '0px';
    }
  }

  private addHideListeners() {
    this.ngZone.runOutsideAngular(async () => {
      // Hide on mouse move
      this.hideSubscriptions.push(
        fromEvent(document, 'mousemove')
          .pipe(debounceTime(100))
          .subscribe(() => this.ngZone.run(() => this.hide()))
      );

      // Hide on touch start (for mobile)
      this.hideSubscriptions.push(
        fromEvent(document, 'touchstart')
          .subscribe(() => this.ngZone.run(() => this.hide()))
      );

      // Hide on scroll
      this.hideSubscriptions.push(
        fromEvent(window, 'scroll', { capture: true })
          .pipe(debounceTime(100))
          .subscribe(() => this.ngZone.run(() => this.hide()))
      );

      // Hide on Ionic content scroll
      const ionContent = document.querySelector('ion-content');
      if (ionContent) {
        const scrollElement = await ionContent.getScrollElement();
        this.hideSubscriptions.push(
          fromEvent(scrollElement, 'ionScroll')
            .pipe(debounceTime(100))
            .subscribe(() => this.ngZone.run(() => this.hide()))
        );
      }

      // Hide on any scroll event (for cases where ion-content might not be available)
      this.hideSubscriptions.push(
        merge(
          ...Array.from(document.querySelectorAll('.ion-content-scroll-host')).map(elem =>
            fromEvent(elem, 'scroll')
          )
        )
          .pipe(debounceTime(100))
          .subscribe(() => this.ngZone.run(() => this.hide()))
      );
    });
  }

  hide(): void {
    if (this.tooltipComponentRef) {
      this.applicationRef.detachView(this.tooltipComponentRef.hostView);
      this.tooltipComponentRef.destroy();
      this.tooltipComponentRef = null;

      // Unsubscribe from all hide listeners
      this.hideSubscriptions.forEach(sub => sub.unsubscribe());
      this.hideSubscriptions = [];
    }
  }
}