import { inject, Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { ConnectionStatus, Network } from '@capacitor/network';
import { NativePermissionService, TPlugin } from './native-permission.service';
import { EventsService } from './events.service';
import { PluginListenerHandle } from '@capacitor/core';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class NativeService {
  private $events = inject(EventsService);
  private $error = inject(ErrorService);
  private $nativePermission = inject(NativePermissionService);

  private networkMonitorTag?: PluginListenerHandle;

  async init() {
    await this.monitorNetwork();
    if (!Capacitor.isNativePlatform()) return;
    await this.lightStatusBar();
  }

  async lightStatusBar() {
    await StatusBar.setStyle({ style: Style.Light });
  }

  // -- Network

  async monitorNetwork() {
    this.$events.networkStatusChange.next(await Network.getStatus());
    this.networkMonitorTag = await Network.addListener("networkStatusChange", (status: ConnectionStatus) => {
      this.$events.networkStatusChange.next(status);
      if(!status.connected) {
        this.$error.showToast({ header: "Uh Oh", message: "It looks like you lost your internet connection. App will not operate until you get back online.", duration: 5000, position:"top", color: "danger", swipeGesture: "vertical" });
      } 
      // else {
      //   this.$error.showToast({ header: "Yay!", message: "You are back online!", duration: 3000, position:"top", color: "success", swipeGesture: "vertical" });
      // }
    });
  }

  unmonitorNetwork() {
    if (this.networkMonitorTag) this.networkMonitorTag.remove();
  }

  // -- Permissions

  getPermissionsForAll() {
    return this.$nativePermission.getPermissionsForAll();
  }

  getPermissionsFor(forPlugin: TPlugin) {
    return this.$nativePermission.getPermissionsFor(forPlugin);
  }

  async requestPermissionsFor(forPlugin: TPlugin) {
    await this.$nativePermission.requestPermissionsFor(forPlugin);
  }
}