import { Component, input, OnInit } from '@angular/core';

@Component({
  selector: 'app-address-list-view',
  templateUrl: './address-list.component.html',
  styleUrls: ['./address-list.component.scss'],
  standalone: true,
})
export class AddressListViewComponent {

  public title = input.required<string>();

}
