<ion-header>
  <app-header background="faded">
    <app-simple-button (click)="previousStep()" color="main" size="large" slot="start" icon="chevron-back-outline" />
  </app-header>
</ion-header>

<ion-content>
  <app-steps-container [step]="currentStep()">
    <div class="step username">
      <app-section-description title="Account" description="Add your mobile number to either sign in or create a new account " />
      <app-login-wrapper>
        <app-input type="tel" [showClearButtonOption]="true" [externalError]="mobileNumberError()" [errorPadding]="true" [value]="mobileNumber()" (valueChange)="onMobileNumberChange($event)" (iconClick)="showTooltip($event)" icon="flag" [customIcon]="true" label="Mobile phone number" />
      </app-login-wrapper>
      <app-label text="We user your mobile number for verification and communication purposes only. " />
    </div>
    <div class="step pin">
      <app-section-description title="Confirm Mobile" [description]="'Enter the code we sent over SMS to ' + mobileNumber() + ':'" />
      <app-otp (otpCodeChange)="checkOTPCode($event)" [(otpCode)]="otp" />
      <div class="try-again">Didn't get an SMS? <app-simple-button [disabled]="sendCodeDisabled()" (click)="sendCode()" icon="refresh-circle-outline" size="large" label="Send again" color="tint" /></div>
    </div>
    <div class="step signup">
      <app-section-description title="Create Account" description="Please enter your name and email address to create an account" />
      <app-login-wrapper>
        <div class="form">
          <app-input type="text" label="First name" [value]="firstName()" (valueChange)="onFirstNameChange($event)" />
          <app-input type="text" label="Last name" [value]="lastName()" (valueChange)="onLastNameChange($event)" />
          <app-input type="email" label="Email address" [value]="email()" (valueChange)="onEmailChange($event)" />
          <div>
            <app-input type="password" label="Password" [value]="password()" (valueChange)="onPasswordChange($event)" />
            <app-password-rules [password]="password()" (validation)="onPasswordValidation($event)" />
          </div>
        </div>
      </app-login-wrapper>
    </div>
    <div class="step password">
      <app-section-description title="Password" description="Please enter your password" />
      <app-login-wrapper>
        <app-input type="password" label="Password" [value]="password()" (valueChange)="onPasswordChange($event)" />
      </app-login-wrapper>
      <app-simple-button (click)="resetPassword()" icon="refresh-circle-outline" size="large" label="Forgot password?" color="tint" />
    </div>
  </app-steps-container>
</ion-content>

<ion-footer>
  <app-footer>
    @switch (currentStep()) {
      @case (0) {
        <app-button [disabled]="processingStep() || !mobileNumberValid()" (clicked)="nextStep()" size="large" label="Continue" iconRight="arrow-forward-outline"  />
      }
      @case (1) {
        <app-button [disabled]="processingStep() || !otpValid()" (clicked)="nextStep()" size="large" label="Validate OTP" iconRight="arrow-forward-outline" />
      }
      @case (2) {
        <app-button [disabled]="processingStep() || !signupValid()" (clicked)="nextStep()" size="large" label="Signup" />
      }
      @case (3) {
        <app-button [disabled]="processingStep() || !loginPasswordValid()" (clicked)="nextStep()" size="large" label="Login" />
      }
    }
  </app-footer>
</ion-footer>