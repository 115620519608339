import { inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { init, track, identify, Identify, setUserId, setSessionId } from '@amplitude/analytics-browser';
import { environment } from 'src/environments/environment';
import packageJson from '../../../package.json';

import { AmplitudeEventKey, AmplitudeEventPropsMap, AmplitudeEvents } from './amplitude_new_events';
import { Device } from '@capacitor/device';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class AmplitudeService {
  private route = inject(ActivatedRoute);

  private readonly silentMode = true;

  private userId?: string;
  private identity?: Identify;
  private os?: string;

  public async init() {
    this.consoleLog("Init");
    await this.initAmplitude();
    this.watchQueryParams();
  }

  public setUserId(id: string | undefined) {
    this.userId = id;
  }

  private async getAppVersion() {
    const onMobile = Capacitor.isNativePlatform();
    if (onMobile) {
      const current = await CapacitorUpdater.current();
      if (current.bundle.version != "builtin") {
        return current.bundle.version;
      }
    }
    return packageJson.version;
  }

  private async initAmplitude() {
    const appVersion = await this.getAppVersion();
    const config = { appVersion, includeUtm: true };
    init(environment.amplitudeKey, this.userId, config);
    this.identity = new Identify();
    identify(this.identity);
    const deviceInfo = await Device.getInfo();
    this.os = deviceInfo.operatingSystem;
  }

  private watchQueryParams() {
    this.route.queryParams.subscribe(params => {
      const keys = Object.keys(params);
      for (const key of keys) {
        this.addUserProp(key, params[key]);
      }
    });
  }

  // Overload for events with required props
  public track<K extends keyof AmplitudeEventPropsMap>(eventKey: K, props: AmplitudeEventPropsMap[K]): void;
  // Overload for events without props
  public track<K extends Exclude<AmplitudeEventKey, keyof AmplitudeEventPropsMap>>(eventKey: K): void;

  public track<K extends AmplitudeEventKey>(
    eventKey: K,
    props?: K extends keyof AmplitudeEventPropsMap ? AmplitudeEventPropsMap[K] : never
  ): void {
    try {
      const event = AmplitudeEvents[eventKey as keyof typeof AmplitudeEvents];
      this.consoleLog("Track Event", event, props);
      track(event, props)
    } catch (err) {
      this.consoleLog("Track Event Error", err);
    }
  }

  public async addUserProp(prop: string, value: any): Promise<void>{
    if (!this.identity) return;
    this.consoleLog("Add User Prop", prop, value);
    this.identity.set(prop, value);
    await identify(this.identity);
  }

  public async resetUser(): Promise<void> {
    try {
      this.consoleLog("Reset User");
      setUserId(undefined);
      setSessionId(Date.now());
    } catch (err) {
      this.consoleLog("Reset User Error", err);
    }
  }

  private consoleLog(...args: any[]) {
    if (this.silentMode) return;
    console.log("[AmplitudeService]", ...args);
  }

}