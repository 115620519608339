<ion-header>
  <app-header>
    <app-simple-button (click)="dismiss()" size="large" icon="close" slot="start" />
  </app-header>
</ion-header>

<ion-content>
  <app-item-full [description]="description()" [price]="price()" [name]="name()" [imageSrc]="imageSrc()" />
</ion-content>

<ion-footer>
  <app-footer>
    <app-checkbox [(ngModel)]="swapEnabled" label="If unavailable, swap for a similar product"></app-checkbox>
    <app-counter [(ngModel)]="count" (inc)="onCountInc()" (dec)="onCountDec()"></app-counter>
    <div class="remove">
      <app-simple-button (click)="removeItem()" label="Remove" size="large" icon="trash" iconLocation="right" color="tint"></app-simple-button>
    </div>
    <app-button (clicked)="updateCart()" size="large" [label]="buttonLabel()" [isPrice]="true" [textRight]="getTotalPrice.toString()" />
  </app-footer>
</ion-footer>