import { ChangeDetectionStrategy, Component, Input, OnInit, computed } from '@angular/core';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TitleComponent  {

  @Input({required: true}) title: string = '';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';

  isSmall = computed(() => this.size === 'small');
  isMedium = computed(() => this.size === 'medium');
  isLarge = computed(() => this.size === 'large');
}
