<button [class.disabled]="isDisabled()" [class.bold]="isBold()" [class.tint]="isTint()" [class.main]="isMain()" [class.large]="isLarge()" [class.medium]="isMedium()" [class.small]="isSmall()" [class.border]="border">
    @if (icon && iconLeft()) {
        <ion-icon [name]="icon" />
    }
    @if (label) {
        {{ label }}
    }
    @if (icon && iconRight()) {
        <ion-icon [name]="icon" />
    }
</button>