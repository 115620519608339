import { Component, computed, inject, input } from '@angular/core';
import { TitleComponent } from '../../primitives/title/title.component';
import { PriceComponent } from '../../elements/price/price.component';
import { DomSanitizer } from '@angular/platform-browser';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-item-full',
  templateUrl: './item-full.component.html',
  styleUrls: ['./item-full.component.scss'],
  standalone: true,
  imports: [TitleComponent, PriceComponent]
})
export class ItemFullComponent {
  private $state = inject(StateService);
  private sanitizer = inject(DomSanitizer);

  public hasAddress = this.$state.hasAddress;
  
  public imageSrc = input.required<string | null>();
  public name = input.required<string>();
  public price = input.required<number>();
  public description = input.required<string>();

  public safeDescription = computed(() => this.sanitizer.bypassSecurityTrustHtml(this.description()));
}