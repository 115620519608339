import { Injectable } from '@angular/core';
import { Dialog } from '@capacitor/dialog';
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';

import { Camera, CameraPermissionState } from '@capacitor/camera';
import { PushNotifications } from '@capacitor/push-notifications';
import { Geolocation } from '@capacitor/geolocation';
import { Capacitor, PermissionState } from '@capacitor/core';
import { BehaviorSubject } from 'rxjs';
import { App } from '@capacitor/app';

export type TPlugin = "CAMERA" | "PHOTOS" | "PUSH_NOTIFICATIONS" | "LOCATION";

@Injectable({
  providedIn: 'root'
})
export class NativePermissionService {
  public readonly cameraPermissionState: BehaviorSubject<CameraPermissionState> = new BehaviorSubject<CameraPermissionState>("prompt");
  public readonly photosPermissionState: BehaviorSubject<CameraPermissionState> = new BehaviorSubject<CameraPermissionState>("prompt");
  public readonly pushNotificationPermissionState: BehaviorSubject<PermissionState> = new BehaviorSubject<PermissionState>("prompt");
  public readonly locationPermissionState: BehaviorSubject<PermissionState> = new BehaviorSubject<PermissionState>("prompt");

  private RATIONALE_MESSAGE = new Map<TPlugin, string>([
    ['CAMERA', 'Camera access is needed to take photos and use the camera features in this app.'],
    ['PHOTOS', 'Photos access is needed to select and upload photos in this app.'],
    ['PUSH_NOTIFICATIONS', 'Push notifications access is needed to send you updates and important information.'],
    ['LOCATION', 'Precies Location access is needed to provide location-based services and improve your app experience.'],
  ]);
  private DENIED_MESSAGE = new Map<TPlugin, string>([
    ['CAMERA', 'Camera access is required to take photos.'],
    ['PHOTOS', 'Photos access is required to choose photos.'],
    ['PUSH_NOTIFICATIONS', 'Push notifications access is required to receive updates.'],
    ['LOCATION', 'Precies Location access is required to use location-based services.'],
  ]);

  constructor() {
    this.checkPermissions();

    App.addListener("appStateChange", async state => {
      if (state.isActive) {
        await this.checkPermissions();
      }
    });
  }

  private onMobile() {
    return Capacitor.isNativePlatform();
  }

  private async checkPermissions() {
    try {
      const cameraPermissions = await Camera.checkPermissions();
      this.cameraPermissionState.next(cameraPermissions.camera);
      this.photosPermissionState.next(cameraPermissions.photos);
    } catch (error) {}
    
    try {
      const notificationsPermissions = await PushNotifications.checkPermissions();
      this.pushNotificationPermissionState.next(notificationsPermissions.receive);
    } catch (error) {}
    
    try {
      const locationPermissions = await Geolocation.checkPermissions();
      this.locationPermissionState.next(locationPermissions.location);
    } catch (error) {}
  }
  
  private getRationaleMessage(forPlugin: TPlugin): string {
    return this.RATIONALE_MESSAGE.get(forPlugin) || 'Permission is required.';
  }

  private async showRationaleDialog(forPlugin: TPlugin): Promise<boolean> {
    const rationaleMessage = this.getRationaleMessage(forPlugin);
    const { value } = await Dialog.confirm({
      title: 'Permission Needed',
      message: rationaleMessage,
      okButtonTitle: 'Yes',
      cancelButtonTitle: 'No'
    });
    return value;
  }

  private getDeniedMessage(forPlugin: TPlugin): string {
    return this.DENIED_MESSAGE.get(forPlugin) || 'Permission is required.';
  }

  private async showDeniedOrLimitedDialog(forPlugin: TPlugin, state: 'denied' | 'limited'): Promise<boolean> {
    const deniedMessage = this.getDeniedMessage(forPlugin);
    const { value } = await Dialog.confirm({
      title: state === 'denied' ? 'Permission Denied' : 'Limited Access',
      message: deniedMessage + (state === 'denied' 
        ? ' Please enable it in your device settings.' 
        : ' You have given limited access. For full access, please enable it in settings.'),
      okButtonTitle: 'Open Settings',
      cancelButtonTitle: 'Cancel'
    });
    return value;
  }

  private async openNativeSettings(forPlugin: TPlugin) {
    const options = { optionAndroid: AndroidSettings.ApplicationDetails, optionIOS: IOSSettings.App };
    if (forPlugin === "PUSH_NOTIFICATIONS") {
      options.optionAndroid = AndroidSettings.AppNotification;
    }
    await NativeSettings.open(options);
  }

  public getPermissionsForAll(): Map<TPlugin, CameraPermissionState | PermissionState> {
    return new Map<TPlugin, CameraPermissionState | PermissionState>([
      ["CAMERA", this.cameraPermissionState.getValue()],
      ["PHOTOS", this.photosPermissionState.getValue()],
      ["PUSH_NOTIFICATIONS", this.pushNotificationPermissionState.getValue()],
      ["LOCATION", this.locationPermissionState.getValue()],
    ]);
  }

  public getPermissionsFor(forPlugin: TPlugin): CameraPermissionState | PermissionState {
    const perm = this.getPermissionsForAll().get(forPlugin);
    if (!perm) throw new Error("Incorrect Permission Type");
    return perm;
  }

  private async _requestPermissionFor(forPlugin: TPlugin) {
    let perm = undefined;
    switch (forPlugin) {
      case "CAMERA":
        perm = await Camera.requestPermissions({ permissions: ["camera"] });
        this.cameraPermissionState.next(perm.camera);
        break;
      case "PHOTOS":
        perm = await Camera.requestPermissions({ permissions: ["photos"] });
        this.photosPermissionState.next(perm.photos);
        break;
      case "PUSH_NOTIFICATIONS":
        perm = await PushNotifications.requestPermissions();
        this.pushNotificationPermissionState.next(perm.receive);
        break;
      case "LOCATION":
        perm = await Geolocation.requestPermissions({ permissions: ["location"] });
        this.locationPermissionState.next(perm.location);
        break;
      default:
        throw new Error("Incorrect Permission Type");
    }
  }

  public async requestPermissionsFor(forPlugin: TPlugin): Promise<CameraPermissionState | PermissionState> {
    if (!this.onMobile()) {
      console.warn("[NativePermissionService]", "Cannot manage permissions on Web platform");
      throw new Error("Cannot manage permissions on Web platform");
    }

    try {
      const permissionState = this.getPermissionsFor(forPlugin);

      if (permissionState === 'granted') {
        return permissionState;
      }

      if (permissionState === 'denied' || permissionState === 'limited') {
        const openSettings = await this.showDeniedOrLimitedDialog(forPlugin, permissionState as 'denied' | 'limited');
        if (openSettings) {
          await this.openNativeSettings(forPlugin);
        }
        return permissionState;
      }

      if (permissionState === 'prompt-with-rationale') {
        const shouldRequestPermission = await this.showRationaleDialog(forPlugin);
        if (!shouldRequestPermission) {
          return permissionState;
        }
      }
      
      await this._requestPermissionFor(forPlugin);
      return this.getPermissionsFor(forPlugin);
    } catch (error) {
      console.error("[NativePermissionService]", `Error requesting ${forPlugin.toLowerCase()} permissions:`, error);
      throw new Error(`Error requesting ${forPlugin.toLowerCase()} permissions`);
    }
  }

  public async disablePermissionsFor(forPlugin: TPlugin): Promise<CameraPermissionState | PermissionState> {
    if (!this.onMobile()) {
      console.warn("[NativePermissionService]", "Cannot manage permissions on Web platform");
      throw new Error("Cannot manage permissions on Web platform");
    }
    try {
      const permissionState = this.getPermissionsFor(forPlugin);

      if (permissionState !== 'granted') return permissionState;
    
      const { value } = await Dialog.confirm({
        title: 'Disable Permission',
        message: 'To disabled the permission, please go to the app settings and disable it.',
        okButtonTitle: 'Open Settings',
        cancelButtonTitle: 'Cancel'
      });

      if (value) {
        await this.openNativeSettings(forPlugin);
      }

      return this.getPermissionsFor(forPlugin);
    } catch (error) {
      console.error("[NativePermissionService]", `Error disabling ${forPlugin.toLowerCase()} permissions:`, error);
      throw new Error(`Error disabling ${forPlugin.toLowerCase()} permissions`);
    }
  }

  public async togglePermissionFor(forPlugin: TPlugin): Promise<CameraPermissionState | PermissionState> {
    if (!this.onMobile()) {
      console.warn("[NativePermissionService]", "Cannot manage permissions on Web platform");
      throw new Error("Cannot manage permissions on Web platform");
    }
    try {
      const permissionState = this.getPermissionsFor(forPlugin);

      if (permissionState == "granted") {
        return await this.disablePermissionsFor(forPlugin);
      } else {
        return await this.requestPermissionsFor(forPlugin);
      }
    } catch (error) {
      console.error("[NativePermissionService]", `Error toggling ${forPlugin.toLowerCase()} permissions:`, error);
      throw new Error(`Error toggling ${forPlugin.toLowerCase()} permissions`);
    }
  }
}