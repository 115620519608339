<ion-header>
  <app-header [border]="true">
    @if(pageState() === "page") {
      <app-simple-button (click)="back()" color="main" size="large" slot="start" icon="chevron-back-outline" />
    }
   
    <app-title slot="main" size="medium" title="Coupons" />
    @if(pageState() === "modal") {
      <app-simple-button (click)="back()" color="main" size="large" slot="end" icon="close" />
    }
  </app-header>
</ion-header>

<ion-content>
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)" >
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <div class="wrapper">
    <div class="content empty">
      <div class="promo-input">
        <app-input [(ngModel)]="inputValue" type="text" placeholder="Enter your coupon code here" [showClearButtonOption]="true"></app-input>
      </div>
      <div class="promo-button">
        <app-button (click)="checkPromotion()" [disabled]="checkPromotionDisabled()" label="Add promo code"></app-button>
      </div>
    </div>
  </div>
  @if (currentPromotions().length > 0 || pastPromotions().length > 0) {
    <div class="wrapper">
      @if (currentPromotions().length > 0) {
        <div class="content active">
          <h3>Active Promotions</h3>
          @for (promotion of currentPromotions(); track $index; let lastPromotion = $index) {
            <app-promotion-row [canSelect]="canSelect()" [canClear]="canClear()" (clear)="clearPromotion(promotion)" (click)="selectPromotion(promotion)" [last]="lastPromotion == currentPromotions().length - 1" [promotion]="promotion"></app-promotion-row>
          }
        </div>
      }

      @if (pastPromotions().length > 0) {
        <div class="content expired">
          <h3>Expired Promotions</h3>
          @for (promotion of pastPromotions(); track $index; let lastPromotion = $index) {
            <app-promotion-row [past]="true" [last]="lastPromotion == pastPromotions().length - 1" [promotion]="promotion"></app-promotion-row>
          }
        </div>
      }
    </div>
  }
  
</ion-content>
