import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { register as registerSwiperElements } from 'swiper/element/bundle';
import { AppComponent } from './app/app.component';
import { AppConfig } from './app.config';
import { environment } from './environments/environment';
import { sentryInit } from './sentry';
import { capgoInit } from './app.updater';

declare global {
  var HubSpotConversations: {
    widget: {
      remove: () => void;
      load: () => void;
      open: () =>  void;
      status: () =>  void;
    };
    on(event: string, callback: Function): void; // Function for creating chat event listener
    off(event: string, callback: Function): void; // Function for removing chat event listener
  };
  var hsConversationsSettings: {}
}

// Don't remove, important for live app updates for users (not Livequery)
capgoInit();
// End

if (environment.production) {
  enableProdMode();
}

sentryInit();
registerSwiperElements();

bootstrapApplication(AppComponent, AppConfig);