import { Component, computed, input, output } from '@angular/core';
import { IonIcon } from "@ionic/angular/standalone";
import { PriceComponent } from '../price/price.component';
import { SimpleButtonComponent } from '../../primitives/simple-button/simple-button.component';
import { InfoBubbleComponent } from '../info-bubble/info-bubble.component';
import { TOrderPrescriptionScriptItem } from '@chemist2u/types-client/C2U/Interfaces';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-prescription-row',
  templateUrl: './prescription-row.component.html',
  styleUrls: ['./prescription-row.component.scss'],
  standalone: true,
  imports: [IonIcon, PriceComponent, SimpleButtonComponent, InfoBubbleComponent, CurrencyPipe]
})
export class PrescriptionRowComponent  {
  public last = input<boolean>(false);
  public info = input<boolean>(false);
  public simple = input<boolean>(false);
  public price = input<boolean>(true);
  public canRemove = input<boolean>(true);
  public canReorder = input<boolean>(false);
  public reorderEvent = output<TOrderPrescriptionScriptItem>();
  public item = input.required<TOrderPrescriptionScriptItem>();

  public productName = computed(() => this.item().productName);
  public productPrice = computed(() => this.item().productQty * this.item().productPrice);
  public includesPostage = computed(() => false);
  public isPriced = computed(() => {
    console.error("[DEBUG] this.item().productQty * this.item().productPrice > 0 && !(this.item().productPBS === '' || this.item().productPBS === undefined)", this.item().productQty * this.item().productPrice > 0 && !(this.item().productPBS === "" || this.item().productPBS === undefined));
    return this.item().productQty * this.item().productPrice > 0 && !(this.item().productPBS === "" || this.item().productPBS === undefined);
  });

  public remove = output<void>();

  removeScript() {
    this.remove.emit();
  }

  reorder() {
    this.reorderEvent.emit(this.item());
  }
}