@if (visible()) {
  <div class="info" [class.promo]="color() === 'promo'" [class.blue]="color() === 'blue'" [class.yellow]="color() === 'yellow'" [class.green]="color() === 'green'">
    @if (hasIcon()) {
      <div class="icon">
        <ion-icon [name]="iconName()" />
      </div>
    }
   
    <div class="content">
      @if (title()) {
        <p class="title">{{title()}}</p>
      }
      @if (sanitized()) {
        <div class="description" (click)="action()" [innerHTML]="sanitizedHTML()"></div>
      } @else {
        <p class="description">{{label()}}</p>
      }
      
    </div>
    @if (closeable()) {
      <ion-icon class="close-icon" name="close" (click)="close()" />
    }
  </div>
  
}

