<div class="content">
  <div class="header">
    @if(multiOrder()) {
      <div class="title">{{label()}}  {{count()}} of 2</div>
    } @else {
      <div class="title">{{label()}}</div>
    }
    <div class="description">{{ description() }}</div>
  </div>
  <div class="products">
    <ng-content select="[slot=products]"></ng-content>
  </div>
  <div class="shipping">
    <ng-content select="[slot=shipping]"></ng-content>
  </div>
  <div class="extra">
    <ng-content select="[slot=extra]"></ng-content>
  </div>
</div>