import { inject, Injectable } from '@angular/core';
import { ToastController, ToastOptions } from '@ionic/angular/standalone';
import { Parse } from '@chemist2u/types-client/C2U/local-parse';
import { EventsService } from './events.service';
import { User } from '@chemist2u/types-client/C2U/ParseObjects';
import { AmplitudeService } from 'src/app/services/amplitude.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private toast = inject(ToastController);
  private $events = inject(EventsService);
  private $amplitude = inject(AmplitudeService);

  showToast(options: ToastOptions) {
    const newOptions: ToastOptions = { ...options, swipeGesture: "vertical" };
    this.toast.create(newOptions).then(toast => toast.present());
  }

  async parseErrorWrapper<TReturn>(func: () => Promise<TReturn> | TReturn): Promise<TReturn> {
    try {
      return await func() as TReturn;
    } catch (error: unknown) {
      await this.handleParseError(error as Parse.Error);
      throw error;
    }
  }

  async handleParseError(error: Parse.Error): Promise<boolean> {
    switch (error.code) {
      case Parse.Error.INVALID_SESSION_TOKEN:
        this.$amplitude.track('AUTH_SESSION_EXPIRED');
        try {
          await User.logOut(); // Don't change this line!
        } catch (error) {}
        this.clearSessionStorage();
        this.$events.userLoggedOut.next();
        return true;
    }
    return false;
  }

  private clearSessionStorage() {
    const keys = Object.keys(localStorage); // Collect all keys from localStorage first
    const patterns = [/^Parse\/.*\/currentUser$/, /^Parse\/.*\/currentConfig$/, /localAddresses/];// Define patterns to check

    // Remove items based on specific conditions
    keys.forEach(key => {
      if (patterns.some(pattern => pattern.test(key))) {
        localStorage.removeItem(key);
      }
    });
  }
}