import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceComponent  {
  public value = input.required<number>();
  
  public priceInteger = computed(() => this.value().toFixed(2).split('.')[0]);
  public priceDecimal = computed(() => this.value().toFixed(2).split('.')[1]);
}